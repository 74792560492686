import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
    func
} from 'prop-types';
import { Row, Col, DatePicker, Table, Tag, Progress, Avatar, Button, Input, Dropdown, Menu, Select, Form, Tooltip, Modal } from "antd";
import { PlusOutlined, SearchOutlined, MoreOutlined, EditOutlined } from "@ant-design/icons";
import { submitProject, updateProject } from '../../redux/actions/projectAction';
import moment from 'moment';
import CreateProject from '../../components/createProject';
// import "./ProjectsDashboard.scss";
const { Option } = Select;
const { Search } = Input;
const data = [
    {
        clientId: "1",
        projectName: "Southwest Airlines",
        location: "Southwest Airlines Co.",
        projectStatus: "1",
        manager: "A Need For Upgrades",
        startDate: "Local School Receives New Grant",
        endDate: 'xyz',
        members: ["A", "B", "C"],
        progress: 100,
    },
];

const statusColors = {
    "Completed": "green",
    "Active": "orange",
    "OnHold": "purple",
    "Initiated": "blue",
};

const getStatusString = (status) => {
    switch (status) {
        case 1:
            return 'Initiated';
        case 2:
            return 'Active';
        case 3:
            return 'OnHold';
        case 4:
            return 'Completed';
        default:
            return 'Unknown';
    }
};

const ProjectDashboard = (props) => {
    const { onSubmitProject, onUpdateProject, reloadData } = props;
    const { setSelectedProj, selectedProject, projectList, clientList, setIsShowProjectData, onProjectClick } = props;
    const [isEditStartDate, setIsEditStartDate] = useState(false);
    const [isEditEndDate, setIsEditEndDate] = useState(false);
    const [isEditprojectStatus, setIsEditprojectStatus] = useState(false);
    const [startDateInput, setStartDateInput] = useState('');
    const [endDateInput, setEndDateInput] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dayShiftHour, setDayShiftHour] = useState('');
    const [nightShiftHour, setNightShiftHour] = useState('');
    const [dayShiftInputHour, setDayShiftInputHour] = useState('');
    const [nightShiftInputHour, setNightShiftInputHour] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [masterProject, setMasterProject] = useState([]);
    const [searchText, onSearch] = useState('');
    const [text, setText] = useState('');
    const [projectId, setProjectId] = useState(0);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [isEditDefaultHourModalVisible, setIsEditDefaultHourModalVisible] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [projectStatus, setProjectStatus] = useState('');
    const [projectForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filterStatus, setFilterStatus] = useState(0);
    const [completedProject, setCompletedProject] = useState(0);
    const [activeProject, setActiveProject] = useState(0);
    const [initiatedProject, setInitiatedProject] = useState(0);
    const [onHoldProject, setOnHoldProject] = useState(0);
    const [updateProjectId, setUpdateProjectId] = useState(0);

    const getSelectedProjectId = (id) => {
        if (masterProject.length > 0) {
            sessionStorage.setItem('activeId', id);
            var activeId = sessionStorage.getItem('activeId');
            setProjectId(parseInt(activeId));
            masterProject.map((data) => {
                if (data.id === id) {
                    setSelectedProj(data);
                    setStartDate(moment(data.startDate));
                    setStartDateInput(moment(data.startDate));
                    setDayShiftHour(data?.config?.technicalLogDefaultShiftHours?.day);
                    setNightShiftHour(data?.config?.technicalLogDefaultShiftHours?.night);
                    setDayShiftInputHour(data?.config?.technicalLogDefaultShiftHours?.day);
                    setNightShiftInputHour(data?.config?.technicalLogDefaultShiftHours?.night);
                    setSelectedStatus(data.projectStatus);
                    setProjectStatus(data.projectStatus);
                    if (data.endDate) {
                        setEndDate(moment(data.endDate));
                        setEndDateInput(moment(data.endDate));
                    } else {
                        setEndDate(null);
                        setEndDateInput(null);
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (projectList?.length !== 0) {
            setMasterProject(projectList);
            let completedCount = 0;
            let activeCount = 0;
            let initiatedCount = 0;
            let onHoldCount = 0;
            projectList.forEach(item => {
                switch (item.projectStatus) {
                    case 4:
                        completedCount++;
                        break;
                    case 2:
                        activeCount++;
                        break;
                    case 1:
                        initiatedCount++;
                        break;
                    default:
                        onHoldCount++;
                        break;
                }
            });
            setCompletedProject(completedCount);
            setActiveProject(activeCount);
            setInitiatedProject(initiatedCount);
            setOnHoldProject(onHoldCount);
            //
            setDataSource(projectList);
            var activeId = sessionStorage.getItem('activeId');
            if (activeId) {
                if (projectList.length > 0) {
                    var position = projectList.find(u => u.id === parseInt(activeId));
                    getSelectedProjectId(position.id);
                }
            } else {
                if (projectList.length > 0 && projectList[0] && projectList[0].projectId) {
                    getSelectedProjectId(projectList[0].id);
                }
            }
        }
    }, [projectList]);

    useEffect(() => {
        if (projectList?.length !== 0) {
            if (filterStatus == 1) {
                const data = projectList.filter((item) => item.projectStatus === 1);
                setDataSource(data);
            } else if (filterStatus == 2) {
                const data = projectList.filter((item) => item.projectStatus === 2);
                setDataSource(data);
            } else if (filterStatus == 3) {
                const data = projectList.filter((item) => item.projectStatus === 3);
                setDataSource(data);
            } else if (filterStatus == 4) {
                const data = projectList.filter((item) => item.projectStatus == 4);
                setDataSource(data);
            } else {
                setDataSource(projectList);
            }
        }
    }, [filterStatus]);

    const showModal = (record) => {
        setUpdateProjectId(record.id)
        if (record?.id) {
            projectForm.setFieldsValue({
                clientName: record?.clientName || '',
                manager: record?.manager || '',
                startDate: record?.startDate ? moment(record.startDate, "DD-MM-YYYY") : null,
                endDate: record?.endDate ? moment(record.endDate, "DD-MM-YYYY") : null,
                projectStatus: record?.projectStatus,
                completedWelds: '-',
                technicianLog: `${record?.config?.technicalLogDefaultShiftHours?.day || '0'} Hour / ${record?.config?.technicalLogDefaultShiftHours?.night || '0'} Hour`
            });
            setIsModalVisible(true);
        }
    };


    const handleCancel = () => {
        setIsModalVisible(false);
        projectForm.resetFields();
    };

    const handleSave = async () => {
        try {
            // Validate form fields
            await projectForm.validateFields();
            const formValues = projectForm.getFieldsValue();
            const updateData = {
                startDate: moment(formValues.startDate).format('MM-DD-YYYY'),
                endDate: moment(formValues.endDate).format('MM-DD-YYYY'),
                projectStatus: formValues.projectStatus,
                config: {
                    technicalLogDefaultShiftHours: {
                        day: dayShiftInputHour,
                        night: nightShiftInputHour
                    }
                }
            };

            // If projectId exists, update the project
            if (updateProjectId) {
                onUpdateProject({ data: updateData, id: updateProjectId });
                setIsModalVisible(false);
            } else {
                notification.warn("Project ID is missing, can't update project.");
            }
        } catch (error) {
            console.error("Validation failed:", error);
            notification.error("Please correct the form errors before saving.");
        }
    };


    const handleProjectClick = (event, data) => {
        if (event === 'project-clicked') {
            onProjectClick();
        }
    };

    const searchData = (e) => {
        const searchText = e.target.value.trim().toLowerCase();

        if (searchText.length > 0) {
            const filteredData = masterProject.filter((value) =>
                value.projectName.toLowerCase().includes(searchText) ||
                value.projectId.toLowerCase().includes(searchText) ||
                value.location.toLowerCase().includes(searchText) ||
                value.manager.toLowerCase().includes(searchText) ||
                value.clientName.toLowerCase().includes(searchText)
            );

            if (filteredData.length > 0) {
                setDataSource(filteredData);
                setSelectedProj([]);
                setStartDate(null);
                setEndDate(null);
                setStartDateInput(null);
                setEndDateInput(null);
                setSelectedStatus('');
                setProjectStatus('');
                setSearchNotFound(false);
            } else {
                notification.warning({
                    message: `No Project Found with "${searchText}"`
                });
                setSearchNotFound(true);
                setText('');
                onSearch('');
            }
        } else {
            // Reset state when search text is cleared
            setDataSource(masterProject);
            setSelectedProj([]);
            setStartDate(null);
            setEndDate(null);
            setStartDateInput(null);
            setEndDateInput(null);
            setSelectedStatus('');
            setProjectStatus('');
            setSearchNotFound(false);
        }
    };


    const onSaveProject = async (data, client) => {
        if (data.endDate) {
            data.endDate = moment(data.endDate).format('MM-DD-YYYY');
        } else {
            data.endDate = null;
        }
        var obj = {
            "projectId": data.projectId,
            "projectName": data.projectName,
            "manager": data.manager,
            "startDate": moment(data.startDate).format('MM-DD-YYYY'),
            "endDate": data.endDate,
            "contractId": data.contractId,
            "pihRefId": data.pihRefId,
            "contractor": data.contractor,
            "location": data.location,
            "clientId": client.id,
            "config": {
                "technicalLogDefaultShiftHours": {
                    "day": data.dayShiftHour,
                    "night": data.nightShiftHour
                }
            }
        };
        await onSubmitProject({ data: obj, clientName: data.clientName });
    };

    const handleStatusChange = value => {
        setSelectedStatus(value);
    };

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }
    const columns = [
        {
            title: "Name",
            dataIndex: "projectName",
            key: "projectName",
            ellipsis: {
                showTitle: true
            },
            render: (text, record) => {
                const status = getStatusString(record.projectStatus);
                let avatarColor;

                switch (status) {
                    case 'Completed':
                        avatarColor = 'green';
                        break;
                    case 'Active':
                        avatarColor = 'orange';
                        break;
                    case 'OnHold':
                        avatarColor = 'purple';
                        break;
                    case 'Initiated':
                        avatarColor = 'blue';
                        break;
                    default:
                        avatarColor = 'default';
                }

                return (
                    <div>
                        <Avatar style={{ marginRight: 8, backgroundColor: avatarColor }}>{text[0]}</Avatar>
                        <span>{truncateTableData(text)}</span>
                        <br />
                        <small style={{ marginLeft: 40, color: "gray" }}>{record.location}</small>
                    </div>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "projectStatus",
            key: "projectStatus",
            render: (status, record) => {
                status = getStatusString(status);
                return <div><Tag color={statusColors[status]}>{status}</Tag></div>;
            }
        },
        {
            title: "Project Id",
            dataIndex: "projectId",
            key: "projectId",
            render: (text, record) => <div >{text}</div>
        },
        {
            title: "Client Name",
            dataIndex: "manager",
            key: "manager",
            render: (text, record) => (
                <div >
                    <strong>{record.clientName}</strong>
                    <br />
                    <small style={{ color: "gray" }}>{text}</small>
                </div>
            ),
        },
        {
            title: "Manager",
            dataIndex: "manager",
            key: "manager",
            render: (text, record) => (
                <div >
                    <strong>{text}</strong>
                </div>
            ),
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            render: (text, record) => (
                <div >
                    <strong>{text}</strong>
                </div>
            ),
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            render: (text, record) => (
                <div >
                    <strong>{text}</strong>
                </div>
            ),
        },
        {
            title: "Members",
            dataIndex: "members",
            key: "members",
            render: (text, record) => {
                const maxDisplay = 5;
                const membersToShow = record.members?.slice(0, maxDisplay) || [];
                const extraMembersCount = (record.members?.length || 0) - maxDisplay;

                const allMembers = record.members?.map(member => `${member.firstName} ${member.lastName}`).join(', ') || "No members";

                // Function to generate random colors
                const getRandomColor = () => {
                    const letters = '0123456789ABCDEF';
                    let color = '#';
                    for (let i = 0; i < 6; i++) {
                        color += letters[Math.floor(Math.random() * 16)];
                    }
                    return color;
                };

                return (
                    <div>
                        <Avatar.Group>
                            {membersToShow.map((member, index) => (
                                <Tooltip key={index} title={`${member.firstName} ${member.lastName}`} trigger={["hover"]}>
                                    <Avatar className="member-avatar" style={{ color: getRandomColor() }}>
                                        {member.firstName.charAt(0)}
                                    </Avatar>
                                </Tooltip>
                            ))}
                            {extraMembersCount > 0 && (
                                <Tooltip title={allMembers} trigger={["hover"]}>
                                    <Avatar style={{ backgroundColor: "whiteSmoke", color: "#000" }}>
                                        +{extraMembersCount}
                                    </Avatar>
                                </Tooltip>
                            )}
                        </Avatar.Group>
                    </div>
                );
            },
        },

        // {
        //     title: "Progress",
        //     dataIndex: "progress",
        //     key: "progress",
        //     render: (text, record) => {
        //         let progress = '';
        //         let status = getStatusString(record.projectStatus);
        //         if (status === 'Completed') {
        //             progress = 100;
        //         } else if (status === 'Active') {
        //             progress = 50;
        //         } else if (status === 'OnHold') {
        //             progress = 25;
        //         } else if (status === 'Initiated') {
        //             progress = 10;
        //         }
        //         return <Progress percent={progress} size="small" />;
        //     }
        // },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            width: '90px',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }} onClick={() => {
                                    // getSelectedProjectId(record.id);
                                    showModal(record)
                                }} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'right'
        }
    ];

    const handleRowClick = (record) => {
        getSelectedProjectId(record.id);
        handleProjectClick('project-clicked');
    };

    const onRow = (record) => {
        return {
            onClick: (event) => {
                // Check if the click target is within the "Actions" column
                if (!event.target.closest('.ant-table-cell:last-child')) {
                    handleRowClick(record);
                }
            },
        };
    };

    return (
        <div className="projects-dashboard">
            <div className="filters">
                <div className="d-flex justify-content-between project-search">
                    <div className="peoject-header">
                        <h2>Projects</h2>
                    </div>
                    <div className="search">
                        <Input placeholder="Enter Text to Serach Project..." onChange={searchData} />
                    </div>
                </div>
                <div className="project-stats">
                    <div className={`stat-item ${filterStatus === 0 ? 'active' : ''}`} onClick={() => setFilterStatus(0)}>
                        <span className="stat-icon">📊</span>
                        <span className="stat-label">Total Projects:</span>
                        <span className="stat-value total-stat">{projectList.length}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 2 ? 'active' : ''}`} onClick={() => setFilterStatus(2)}>
                        <span className="stat-icon" style={{ color: 'orange' }}>🔥</span>
                        <span className="stat-label">Active:</span>
                        <span className="stat-value" style={{ color: 'orange' }}>{activeProject}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 1 ? 'active' : ''}`} onClick={() => setFilterStatus(1)}>
                        <span className="stat-icon" style={{ color: 'blue' }}>🚀</span>
                        <span className="stat-label">Initiated:</span>
                        <span className="stat-value" style={{ color: 'blue' }}>{initiatedProject}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 3 ? 'active' : ''}`} onClick={() => setFilterStatus(3)}>
                        <span className="stat-icon" style={{ color: 'purple' }}>⏸️</span>
                        <span className="stat-label">On Hold:</span>
                        <span className="stat-value" style={{ color: 'purple' }}>{onHoldProject}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 4 ? 'active' : ''}`} onClick={() => setFilterStatus(4)}>
                        <span className="stat-icon" style={{ color: 'green' }}>✅</span>
                        <span className="stat-label">Completed:</span>
                        <span className="stat-value" style={{ color: 'green' }}>{completedProject}</span>
                    </div>
                    <div>
                        <CreateProject
                            projectForm={projectForm}
                            onSaveProject={onSaveProject}
                            clientList={clientList}
                        />
                    </div>
                </div>
            </div>

            <Table
                columns={columns}
                dataSource={dataSource}
                onRow={onRow}
                className="dashBoard-table"
                scroll={{ y: 'calc(100vh - 170px)' }}
                // pagination={dataSource.length > 10 ? true : false}
                pagination={false}
            />

            <Modal
                width='50vw'
                className="pipeline-modal"
                title={`Project :- ${selectedProject?.projectName || ''}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSave}>
                        Save
                    </Button>,
                ]}
            >
                <Form
                    form={projectForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Client"
                                name="clientName"
                            >
                                <Input
                                    placeholder="Enter Client Name"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Manager"
                                name="manager"
                            >
                                <Input
                                    placeholder="Enter Manager Name"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="date-picker"
                                label="Start Date"
                                name="startDate"
                            >
                                <DatePicker
                                    allowClear={false}
                                    format="DD-MM-YYYY"
                                    defaultValue={startDateInput}
                                    onChange={(date) => setStartDateInput(date)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="date-picker"
                                label="End Date"
                                name="endDate"
                            >
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    allowClear={false}
                                    defaultValue={endDateInput}
                                    onChange={(date) => setEndDateInput(date)}
                                    disabledDate={(current) =>
                                        startDateInput && current < moment(startDateInput, 'DD-MM-YYYY')
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Project Status"
                                name="projectStatus"
                            >
                                <Select
                                    className='client-select'
                                    value={selectedStatus}
                                    onChange={handleStatusChange}
                                >
                                    <Option value={1}>Initiated</Option>
                                    <Option value={2}>Active</Option>
                                    <Option value={3}>On Hold</Option>
                                    <Option value={4}>Completed</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Completed Welds"
                                name="completedWelds"
                                initialValue="-"
                            >
                                <Input
                                    placeholder="Completed Welds"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Technician Log Default Day/Night Shift"
                                name="technicianLog"
                            >
                                <Input
                                    value={`${dayShiftInputHour} Hour / ${nightShiftInputHour} Hour`}
                                    size="large"
                                    onChange={(e) => {
                                        const [day, night] = e.target.value.split(' / ').map(val => parseInt(val));
                                        setDayShiftInputHour(day);
                                        setNightShiftInputHour(night);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
ProjectDashboard.propTypes = {
    onSubmitProject: func.isRequired,
    onUpdateProject: func.isRequired
};

const mapDispatchToProps = {
    onSubmitProject: submitProject,
    onUpdateProject: updateProject,
};

export default connect(null, mapDispatchToProps)(ProjectDashboard);
