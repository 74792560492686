/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Router, Route, Switch, Redirect
} from 'react-router-dom';
import { Layout } from 'antd';
import routes from './routes';
import history from '../services/history';
import LoginPage from '../pages/Login/Login';
import './style.scss';
import Sidebar from '../layout/sidebar';
import NotFoundPage from '../pages/NotFound';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { authenticateCurrentUser, getAwsGroupAccess } from '../services/auth';
import { getCurrentUser } from '../sagas/auth';
import { connect } from 'react-redux';
import get from 'lodash/get';
// import { Hub } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import ForgotPassword from '../pages/Login/ForgotPassword';
import ResetPassword from '../pages/Login/ResetPassword';
import SignUp from '../pages/Login/SignUp';
import EquipmentCheckComingsoon from '../components/EquipmentCheckComingsoon';
import { getAccess } from '../services/teamMembers';

const AppContainer = (isLogin) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isWebAcess, setIsWebAcess] = useState(false);
  const [userAccess, setUserAccess] = useState([]);
  const [isShowProjectData, setIsShowProjectData] = useState(false);

  Hub.listen(
    'auth',
    // eslint-disable-next-line no-unused-vars
    async ({ payload: { event, data } }) => {
      switch (event) {
        case 'cognitoHostedUI': // set user data and get that data below
          break;
        case 'signIn':
          setIsAuthenticated(true);
          break;
        case 'signOut':
          setIsAuthenticated(false);
          break;
        case 'cognitoHostedUI_failure':
          break;
        default:
          break;
      }
    },
    []
  );

  /** Handling Reloadin or Refreshing page (from browser) */
  // useEffect(() => {
  //   const pageAccessedByReload = (
  //     (window.performance.PerformanceNavigationTiming && window.performance.PerformanceNavigationTiming.type === 1) ||
  //     window.performance
  //       .getEntriesByType('navigation')
  //       .map((nav) => nav.type)
  //       .includes('reload')
  //   );

  //   console.log(pageAccessedByReload);
  //   if (pageAccessedByReload) {
  //       history.push('/');
  //   }
  // }, [])

  useEffect(() => {

    const element = document.getElementById('body-wrapper');
    const theme = sessionStorage.getItem('theme');
    if (theme === 'True') {
      element.classList.add('light-theme');
    } else {
      element.classList.remove('light-theme');
    }

    authenticateCurrentUser()
      .then(async (data) => {
        //
        setUserAccess(data.signInUserSession.accessToken.payload["cognito:groups"])
        if (data.signInUserSession.accessToken.payload["cognito:groups"] && data.signInUserSession.accessToken.payload["cognito:groups"].includes('web-page')) {
          setIsWebAcess(true);
        }
        localStorage.setItem("is-web-page", isWebAcess);
        //
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
        localStorage.clear();
        sessionStorage.clear();
      });
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute userAccess={userAccess} isWebAcess={isWebAcess} exact path="/login" isLogin={isAuthenticated} component={LoginPage} />
        <PublicRoute userAccess={userAccess} isWebAcess={isWebAcess} exact path="/signup" isLogin={isAuthenticated} component={SignUp} />
        <PublicRoute userAccess={userAccess} isWebAcess={isWebAcess} path="/forgot-password" isLogin={isAuthenticated} component={ForgotPassword} />
        <PublicRoute userAccess={userAccess} isWebAcess={isWebAcess} path="/reset-password" isLogin={isAuthenticated} component={ResetPassword} />
        <Route path="/notFound" component={NotFoundPage} />
        <Route path="/asset-management/maintenance-history" component={isAuthenticated ? EquipmentCheckComingsoon : LoginPage} />
        {/* render={() => (isAuthenticated ? <NotFoundPage /> : <Redirect to="/login" />)}/> */}
        <>
          <Layout className="h-100">
            <Sidebar setIsShowProjectData={setIsShowProjectData} />
            <Layout.Content className="h-100 pt-3 px-4">
              <Switch>
                {routes.map((route) => (
                  <PrivateRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    isLogin={isAuthenticated}
                    component={((props) => (
                      <route.layout {...props}>
                        <route.component {...props} isAuthenticated={isAuthenticated} isShowProjectData={isShowProjectData} setIsShowProjectData={setIsShowProjectData} />
                      </route.layout>
                    ))}
                  />
                ))}
                <PrivateRoute path="*" component={() => <Redirect to="/notFound" />} />

              </Switch>
            </Layout.Content>
          </Layout>
        </>
      </Switch>
    </Router>
  );
};

const mapStateToProps = ({ auth }) => {
  const isLogin = get(auth, 'isLogin', false);
  return {
    isLogin,
  };
};

export default connect(mapStateToProps)(AppContainer);
// export default AppContainer;
