/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Row, Table, Tooltip, Col, Typography, Modal, Form, Input, notification, Button, Select, Divider, Space, DatePicker } from 'antd';
const { Title, Text } = Typography;
import { readFile, utils } from "xlsx";
import DSRTabList from '../layout/DSRTabs';
import { fetchDsrLogs } from '../redux/dsr/action'
import { connect } from 'react-redux';
import { get } from 'lodash';
import { FilePdfOutlined } from '@ant-design/icons'
import moment from 'moment';
import pdf from "../assets/template.pdf"
import { fetchSowDetails } from '../redux/sowDetails/actions';

const { RangePicker } = DatePicker;
const { Option } = Select;

const DSR = (props) => {

    const {
        selectedProject,
        dsrLogs,
        isDsrLogsLoading,
        onFetchDsrLogs,
        onFetchSowDetails,
        sowDetails,
        isSowLoading
    } = props;
    const dsrLog = dsrLogs.sort(
        (a, b) => new Date(b.logDate) - new Date(a.logDate)
    );
    const [isAddMemberVisible, setIsAddMemberVisible] = useState(false);
    const [selectedData, setSelectedDateData] = useState({});
    const [selectedRange, setSelectedRange] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [selectedPipeline, setSelectedPipeline] = useState('')
    const [filteredData, setFilteredData] = useState([]);
    const [dsrSowId, setDsrSowId] = useState('');
    const [selectPipeline] = Form.useForm();

    useEffect(() => {
        const projId = get(selectedProject, 'id', undefined);
        if (selectedProject && selectedProject.id) {
            onFetchDsrLogs({ selectedProject });
            setSelectedRange([]);
            onFetchSowDetails(projId)
        }
    }, [selectedProject]);

    useEffect(() => {
        setLocationData(sowDetails);
    }, [sowDetails]);

    //
    useEffect(() => {
        setFilteredData(dsrLogs);
    }, [dsrLogs]);

    const generatePDF = () => {
        let link = document.createElement('a')
        link.href = pdf
        link.download = "test.pdf"
        link.click()
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'logDate',
            key: 'date',
            render: (text, record) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { showModal(record) }}>
                        {text ? moment(text).format('DD MMM YYYY') : '-'}
                    </div>
                );
            },
            align: 'center',
        },
        {
            title: 'Created By',
            key: 'createdBy',
            render: (text, record) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { showModal(record) }}>{record.userFirstName} {record.userLastName}</div>
                );
            },
            align: 'center'
        },
        // {
        //     title: 'Created Time',
        //     dataIndex: 'logSubmitDate',
        //     key: 'createdTime',
        //     align: 'center',
        //     render: (text) => {
        //         return (
        //             <div>{text ? moment(text).format('DD MMM YYYY'): '-'}</div>
        //         );
        //     },
        // },
        {
            title: 'Pipeline',
            dataIndex: 'pipelineSection',
            key: 'pipelineSection',
            align: 'center',
            render: (text, record) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { showModal(record) }}>
                        {text}
                    </div>
                );
            },
        },
        // {
        //     title: 'Comments',
        //     dataIndex: 'comments',
        //     key: 'comments',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <div style={{ cursor: 'pointer' }} onClick={() => { showModal(record) }}>
        //                 { text }
        //             </div>
        //         );
        //     },
        // },
        {
            title: 'Report',
            dataIndex: 'reportDetails',
            key: 'reportDetails',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        {record && record.reportDetails !== null && JSON.stringify(record.reportDetails) != '{}' ?
                            <Tooltip title="View / Download Report" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <Button href={record?.reportDetails?.fileUrl} className='p-0 pdfIcon border-0' size='small' target="_blank" rel="noopener noreferrer"
                                    >
                                        <FilePdfOutlined className='pdfIconRed' />
                                    </Button>
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="Report Not Generated" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FilePdfOutlined className='gradepdfIconRed' style={{ cursor: 'not-allowed' }} />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
        }
    ];

    const showModal = (record) => {
        setDsrSowId(record.sowId)
        setIsAddMemberVisible(true);
        setSelectedDateData(record);
    }

    const handleCancel = () => {
        setIsAddMemberVisible(false);
    }

    const handleCustomDateSelect = async (date, dateString) => {
        if (date) {
            setSelectedRange(date);
            let dateObj = {
                fromDate: moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss'),
                toDate: moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss'),
            }
            if (selectedProject && selectedProject.id) {
                onFetchDsrLogs({ selectedProject, dateObj });
            } else {
                notification.warning({
                    message: 'Please select Project to create Pipeline'
                });
            }
        } else {
            setSelectedRange(null);
            onFetchDsrLogs({ selectedProject });
        }
    }


    const handlePipelineChange = (value) => {
        if (value) {
            setDsrSowId(value)
            const filterList = dsrLogs.filter(pipeLine => pipeLine.sowId == value)
            setFilteredData(filterList)
        } else {
            setFilteredData(dsrLogs)
        }
    }
    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div className="inspection-logs p-0 d-flex mb-3 justify-content-between align-items-center tab-content-title">
                <div className='dsr-select d-flex'>
                    <Title className="mb-0 mt-1" style={{ marginRight: '20px' }} level={4}>
                        Daily Site Report
                    </Title>
                    <Form
                        form={selectPipeline}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            name="location"
                            required
                        >
                            <Select
                                className='pipeline-select'
                                placeholder='Select Pipeline'
                                onChange={(value) => handlePipelineChange(value)}
                                // value={location}
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (option.children).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {locationData && locationData.map((item, i) => (
                                    <Option key={i} value={item?.id}>
                                        {item?.pipelineSection}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>

                </div>
                <Space>
                    <DatePicker.RangePicker
                        className="custom-date-picker"
                        allowClear={true}
                        format="DD-MMM-YYYY"
                        onChange={handleCustomDateSelect}
                        value={selectedRange}
                    // defaultValue={[moment(selectedCustomDateInput), moment(selectedCustomDateInput)]}
                    // disabledDate={current => startDateInput && endDateInput
                    //     && (current < moment(startDateInput, "DD-MM-YYYY HH:mm") || current > moment(endDateInput, "DD-MM-YYYY HH:mm"))}
                    />
                </Space>
            </div>
            <div className="custom-line" style={{ marginTop: '25px' }} />
            <div className='addTeamDetails'>
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={[...filteredData]}
                    className="h-100 pipeline-table "
                    scroll={{ y: 'calc(100vh - 290px)' }}
                    loading={isDsrLogsLoading}
                />
            </div>

            <Modal
                width="95vw"
                title={
                    <Row>
                        <Col flex={1}>
                            <div>Daily Site Report</div>
                        </Col>
                        <Col flex={0}>
                            <div style={{ textAlign: 'right' }}>{moment(selectedData.logDate).format('DD MMM YYYY')}</div>
                        </Col>
                    </Row>
                }
                centered
                visible={isAddMemberVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
                bodyStyle={{
                    maxHeight: '70vh',
                    overflowY: 'auto',
                    minHeight: '640px'
                }}
            >
                <div>
                    <DSRTabList selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ dsrReducer, sow }) => {
    const dsrLogs = get(dsrReducer, 'dsrLogsList', []);
    const isDsrLogsLoading = get(dsrReducer, 'isDsrLogsLoading', []);
    const sowDetails = get(sow, 'sowData', []);
    const isSowLoading = get(sow, 'isSowLoading', []);
    return {
        dsrLogs,
        sowDetails,
        isDsrLogsLoading,
        isSowLoading
    }
}

const mapDispatchToProps = {
    onFetchDsrLogs: fetchDsrLogs,
    onFetchSowDetails: fetchSowDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(DSR);