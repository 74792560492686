import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Typography, Modal, Button, Input, Upload, Form, message } from 'antd';
import history from '../../services/history';
import { authenticateCurrentUser } from '../../services/auth';
import { getAccess } from '../../services/teamMembers';
import { RightCircleOutlined } from '@ant-design/icons';

const DocControl = () => {

    // const [showHeatProfileModal, setHeatProfileModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);
    /** get user Access */
    useEffect(() => {
        const UserGroup = localStorage.getItem('UserGroup') ?? [];
        if (!UserGroup || UserGroup.length === 0) {
            authenticateCurrentUser()
                .then(async (data) => {
                    let response = await getAccess(data.username)
                    const userAccess = response.map(item => item?.GroupName)
                    setUserName(data.username);
                    setUserGroup(userAccess);
                    localStorage.setItem("UserGroup", userAccess);
                    setUserGroup(UserGroup)
                })
                .catch((error) => {
                    console.log('error', error);

                });
        }
        else
            setUserGroup(UserGroup)
    }, [history]);

    return (
        <div className="d-flex flex-column h-100">
            <div className="flex-auto">
            <div className="d-flex mb-3 align-items-center tab-content-title">
                    <Typography className="project-title" style={{ fontSize: '25px' }} level={4}>
                        Doc Control
                    </Typography>
                </div>
                <div className="custom-line" />
                <div >
                    <Row gutter={24} className="detail-rows">
                        {
                            userGroup && userGroup.length > 0 && userGroup.includes('calibration-normal-user') &&
                            <Col span={40} onClick={() => {
                                history.push('/calibration');
                            }} >
                                    <Card className='custom-card2'

                                >
                                        <div style={{ marginTop: '-15px' }} >
                                            <div >
                                                Calibration 
                                            </div>
                                            <div style={{ paddingTop: '10px' }}>
                                                <RightCircleOutlined></RightCircleOutlined>
                                            </div>
                                        </div>
                                </Card>
                            </Col>
                        }
                       
                    </Row>
                </div>
            </div>


        </div>
    );
}

export default DocControl