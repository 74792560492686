/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Col, Typography, Form, Row, Input, Button, message, Spin, Table, Tooltip, DatePicker, notification, Select, InputNumber, Upload } from 'antd';
import { FilePdfOutlined, EditOutlined, DeleteOutlined, UploadOutlined, CheckOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import get from 'lodash/get';
const { Title, Text } = Typography;
import { fetchToolBoxList, addToolBox, updateToolBox, deleteToolBox, deleteToolBoxListFile } from '../redux/toolboxAdmin/action';
import { connect } from 'react-redux';

const ToolboxAdmin = (props) => {

    const {
        toolboxList,
        istoolboxListLoading,
        onFetchToolBoxList,
        onAddToolBox,
        onUpdateToolBox,
        onDeleteToolBox,
        onDeleteToolBoxListFile
    } = props;

    const { Option } = Select;
    // const { Column, ColumnGroup } = Table;
    let s3Bucketname;
    if (process.env.REACT_APP_STAGE == 'dev') {
        s3Bucketname = 'pih-dev-server-bucket'
    }
    if (process.env.REACT_APP_STAGE == 'staging') {
        s3Bucketname = 'pih-staging-server-bucket'
    }
    if (process.env.REACT_APP_STAGE == 'prod') {
        s3Bucketname = 'pih-production-server-bucket'
    }

    const [tagTitle, setTagTitle] = useState('');
    const [tagPrepopulatedDiscussion, setTagPrepopulatedDiscussion] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');
    //
    const { Title } = Typography;
    const { TextArea } = Input;
    //
    const [toolboxlist, setToolboxlist] = useState([]);
    const [toolboxListModalVisible, setToolboxListModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [filteredList, setFilteredList] = useState([]);
    const [searchKeyExist, setSearchKeyExist] = useState('');
    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [isEditTitle, setIsEditTitle] = useState(false);
    const [updateTagTitle, setUpdateTagTitle] = useState('');
    const [editListId, setEditListId] = useState('');
    const [pdfModalVisible, setPdfModalVisible] = useState(false)
    const [selectedPdfListTitle, setSelectedPdfListTitle] = useState('');
    const [pdfList, setPdfList] = useState([]);
    const [fileVersion, setFileVersion] = useState();
    const [pdfListVal, setPdfListVal] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    let name;
    if (user.name) {
        name = user.name;
    } else {
        name = user["custom:first_name"] + " " + user["custom:last_name"];
    }

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();

    form.setFieldsValue({
        createdBy: name,
        createdAt: moment(formattedDate)
    });

    const communicationTypes = [
        { code: 'SA', label: 'Safety Alert' },
        { code: 'TB', label: 'Toolbox Talk' },
        { code: 'EN', label: 'Engineering Notification' },
        { code: 'QA', label: 'Quality Alert' },
        { code: 'EA', label: 'Environmental Alert' },
    ];
    //Table column
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            align: 'center',
            width: '60px',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Title',
            dataIndex: 'tagTitle',
            key: 'tagTitle',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Type',
            dataIndex: 'communicationType',
            key: 'communicationType',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => {
                const foundType = communicationTypes.find((type) => type.code === data);
                return foundType ? foundType.label : data;
            },

        },
        {
            title: 'Description',
            dataIndex: 'tagPrepopulatedDiscussion',
            key: 'tagPrepopulatedDiscussion',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (text) => moment(text).format("DD-MMM-YYYY")
        },
        // {
        //     title: 'Updated Date',
        //     dataIndex: 'updatedAt',
        //     key: 'updatedAt',
        //     align: 'left',
        //     ellipsis: {
        //         showTitle: false
        //     },
        //     render: (text) => moment(text).format("DD-MMM-YYYY")
        // },
        {
            title: 'Attachement',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            width: '90px',
            render: (text, record) => (
                <div>
                    {record.crdmFiles.length > 1 ? <Tooltip title="View File">
                        <Button onClick={() => handlePdfModal(record)} className='p-0 pdfIcon border-0' size='small' target="_blank" rel="noopener noreferrer">
                            <FilePdfOutlined className='pdfIconRed' />
                        </Button>
                    </Tooltip> : record.crdmFiles[0]?.fileKey ? <Tooltip title="View File">
                        <Button href={`https://${s3Bucketname}.s3.us-west-2.amazonaws.com/${record.crdmFiles[0]?.fileKey}`} className='p-0 pdfIcon border-0' size='small' target="_blank" rel="noopener noreferrer">
                            <FilePdfOutlined className='pdfIconRed' />
                        </Button>
                    </Tooltip> : <Tooltip title="Report Not Generated" >
                        <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                            <FilePdfOutlined className='gradepdfIconRed' style={{ cursor: 'not-allowed' }} />
                        </span>
                    </Tooltip>}
                </div>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
                <div>
                    <Tooltip title="Edit">
                        <EditOutlined className='txtIcon' style={{ marginLeft: '10px', fontSize: '18px' }} onClick={() => showAddEditListModal(record, true)} />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteOutlined className='txtIcon' style={{ marginLeft: '10px', fontSize: '18px' }} onClick={() => handleDeletion(record)} />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }

    useEffect(() => {
        onFetchToolBoxList();
    }, [])

    useEffect(() => {
        setFilteredList(toolboxList)
        setToolboxlist(toolboxList)
    }, [toolboxList])

    //search Toolbox list by  Title
    const typeMapping = {
        'SA': 'SA – Safety Alert',
        'TB': 'TB – Toolbox Talk',
        'EN': 'EN – Engineering Notification',
        'QA': 'QA – Quality Alert',
        'EA': 'EA – Environmental Alert'
    };

    const onSearchByTitle = (e) => {
        let data = e.target.value.toLowerCase();
        setSearchKeyExist(data);
        const filterList = toolboxlist.filter((item) => {
            const tagTitle = item.tagTitle ? item.tagTitle.toLowerCase() : '';
            const createdBy = item.createdBy ? item.createdBy.toLowerCase() : '';
            const communicationType = item.communicationType ? typeMapping[item.communicationType].toLowerCase() : '';
            const tagPrepopulatedDiscussion = item.tagPrepopulatedDiscussion ? item.tagPrepopulatedDiscussion.toLowerCase() : '';

            return tagTitle.includes(data) ||
                createdBy.includes(data) ||
                communicationType.includes(data) ||
                tagPrepopulatedDiscussion.includes(data);
        });
        setFilteredList(filterList);
    };



    //Edit toolbox list data
    const showAddEditListModal = async (record, edit) => {
        let userDt = {};
        if (record !== '') {
            userDt.tagTitle = record.tagTitle;
            userDt.communicationType = record.communicationType;
            userDt.createdBy = record.createdBy;
            userDt.tagPrepopulatedDiscussion = record.tagPrepopulatedDiscussion;
            userDt.createdAt = moment(formattedDate);

            form.setFieldsValue(userDt);
            setTagTitle(record.tagTitle);
            setUpdateTagTitle(record.tagTitle);
            setEditListId(record.id)
            setPdfList(record.crdmFiles)
            setPdfListVal(record.crdmFiles)
            setFileVersion(record.crdmFiles[record.crdmFiles.length - 1]?.fileVersion)
        }
        setIsEdit(edit);
        setDisableFileUpload(false);
        setToolboxListModalVisible(true);
    };
    console.log("pdfListVal", pdfListVal.length)
    //Add and Update new data in List
    const handleOk = () => {
        let payload = {};
        form.validateFields()
            .then((data) => {
                if (data.tagTitle.trim() && data.communicationType && data.tagPrepopulatedDiscussion.trim() && data.createdBy.trim()) {
                    // if (isEdit && pdfList.length == 0) {
                    if (isEdit) {
                        if (pdfListVal.length !== 0) {
                            payload = {
                                tagTitle: data.tagTitle,
                                communicationType: data.communicationType,
                                tagPrepopulatedDiscussion: data.tagPrepopulatedDiscussion,
                                createdBy: data.createdBy,
                                crdmFiles: fileList,
                                id: editListId
                            };
                            let fileV = parseInt(fileVersion);
                            if (!isNaN(fileV)) {
                                fileV = fileV + 1;
                            } else {
                                fileV = 1;
                            }
                            onUpdateToolBox({ data: payload, id: editListId, fileVersion: fileV });
                            resetFields();
                        } else {
                            showNotification();
                        }
                    } else if (fileList.length) {
                        payload = {
                            tagTitle: data.tagTitle,
                            communicationType: data.communicationType,
                            tagPrepopulatedDiscussion: data.tagPrepopulatedDiscussion,
                            createdBy: data.createdBy,
                            crdmFiles: fileList,
                        };
                        onAddToolBox(payload);
                        resetFields();
                    } else {
                        showNotification();
                    }
                } else {
                    showNotification();
                }
            });
    };

    const showNotification = () => {
        notification.warning({
            message: 'Fill all the required fields!'
        });
    };

    const resetFields = () => {
        setTimeout(() => {
            setToolboxListModalVisible(false);
            form.resetFields();
            setFileList([]);
            setPdfListVal([])
            setDisableFileUpload(false);
            setIsEdit(false);
        }, 1000);
    };


    //Delete List data
    const handleDeletion = (record) => {
        Modal.confirm({
            className: 'custom-text-clr',
            title: 'Are you sure you want to delete this data?',
            async onOk() {
                try {
                    onDeleteToolBox({ id: record.id })
                } catch (error) {
                    console.error('Error deleting data:', error);
                    message.error('Data delete failed.');
                    throw error;
                }
            },
            onCancel() { },
        });
    };

    const deleteToolListFile = (id) => {
        Modal.confirm({
            className: 'custom-text-clr',
            title: 'Are you sure you want to delete this file?',
            async onOk() {
                try {
                    onDeleteToolBoxListFile({ id: id })
                    const updatedPdfList = pdfList.filter(pdf => pdf.id !== id);
                    setPdfList(updatedPdfList);
                    setPdfListVal(updatedPdfList);
                } catch (error) {
                    console.error('Error deleting data:', error);
                    message.error('File delete failed.');
                    throw error;
                }
            },
            onCancel() { },
        });
    };




    const filePropsForOne = {
        onRemove: () => {
            setFileList([]);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            setPdfListVal([file])
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };

    const handlePdfModal = (record) => {
        setSelectedPdfListTitle(record.tagTitle)
        setPdfList(record.crdmFiles)
        setPdfListVal(record.crdmFiles)
        setPdfModalVisible(true);
    }

    const pdfModalCancel = () => {
        setPdfModalVisible(false);
        setSelectedPdfListTitle('')
        setPdfList([])
        setPdfListVal([])
    }

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleCancel = () => {
        setIsEdit(false);
        form.resetFields();
        setToolboxListModalVisible(false);
        setTagTitle('');
        setUpdateTagTitle('');
        setEditListId('')
        setPdfList([])
        setPdfListVal([])
        setFileVersion()
        setFileList([]);
        setDisableFileUpload(false);
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    return (
        <>
            <div className="h-100 overflow-y-hidden  overflow-x-auto">
                <div>
                    <div className='toolbox_admin_modal mb-2'>
                        <Title className="mb-0" level={4}>
                            List of Communications
                        </Title>
                        <div className='searchHeatProfileList'>
                            <Input
                                placeholder='Search Communications'
                                onChange={onSearchByTitle}
                                allowClear
                            />
                        </div>
                        <div className="text-right new-calibration">
                            <Text onClick={() => setToolboxListModalVisible(true)} className="ps-3 add-new-calibration"  >
                                <span className="icon-22">
                                    <svg>
                                        <use xlinkHref="#add" />
                                    </svg>
                                </span>
                                Create New Communication
                            </Text>

                        </div>
                    </div>
                </div>
                <hr />
                <Table
                    pagination={{ defaultPageSize: 20, current: 1 }}
                    columns={columns}
                    dataSource={filteredList}
                    // className="h-100 pipeline-table heatprofile_table equipPaginationTable"
                    className="masterUserManagmentTable"
                    scroll={{ x: toolboxlist.length > 0 ? 1220 : 0, y: 'calc(100vh - 270px)' }}
                    loading={{
                        indicator: <div><Spin /> Loading...</div>,
                        spinning: istoolboxListLoading
                    }}
                />


            </div>
            {
                toolboxListModalVisible ?
                    <Modal
                        width={700}
                        className="toolbox-admin"
                        title={isEdit ? (
                            // isEditTitle ? (
                            //     <div>
                            //         <Input
                            //             className='edit_station_input'
                            //             value={updateTagTitle}
                            //             onChange={(e) => setUpdateTagTitle(e.target.value)}
                            //         />
                            //         <CloseOutlined
                            //             className='edit_close_icon'
                            //             onClick={() => setIsEditTitle(false)}
                            //         />
                            //         <CheckOutlined
                            //             className='edit_apply_icon'
                            //             onClick={() => {
                            //                 setTagTitle(updateTagTitle);
                            //                 setIsEditTitle(false);
                            //             }}
                            //         />
                            //     </div>
                            // ) : (
                            //     <div>
                            //         <Tooltip title={tagTitle}>
                            //             <Text
                            //                 className='m-0 station_name_'
                            //                 onClick={() => setIsEditTitle(true)}
                            //             >
                            //                 {tagTitle}
                            //             </Text>
                            //         </Tooltip>
                            //         <Tooltip title='Update Station Name'>
                            //             <EditOutlined
                            //                 className='edit_station_name'
                            //                 onClick={() => setIsEditTitle(true)}
                            //             />
                            //         </Tooltip>
                            //     </div>
                            // )
                            updateTagTitle
                        ) : (
                            'New Communication'
                        )}


                        centered
                        visible={toolboxListModalVisible}
                        footer={isEdit ? [
                            <Button key="submit1" type="primary" onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="submit2"
                                type='primary'
                                // disabled={shareButtonVisible} 
                                loading={istoolboxListLoading}
                                onClick={handleOk}
                            >
                                Update
                            </Button>
                        ] : [
                            <Button key="submit3" type="primary" onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit4"
                                size="medium"
                                shape="round"
                                type='primary'
                                disabled={!tagTitle || !tagPrepopulatedDiscussion || !fileList.length}
                                loading={istoolboxListLoading}
                                onClick={handleOk}
                            >
                                Create
                            </Button>
                        ]}
                    >
                        {/* <Spin spinning={loading} tip="Wait: PDF is being generated and uploaded..."> */}
                        <Form
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                            getcontainer={false}
                            form={form}
                        >
                            <Row gutter={12} className="upload-row">
                                <Col lg={12}>
                                    <Form.Item label='Title' name="tagTitle" required>
                                        <Input
                                            value={tagTitle}
                                            onChange={(e) => setTagTitle(e.target.value)}
                                            size="large"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={12}>
                                    <Form.Item label='Type' name="communicationType" required>
                                        <Select
                                            className='client-select'
                                            placeholder='Select Type'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            // value={communicationType}
                                            filterOption={(input, option) =>
                                                (option.children).toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            <Option value='SA'>SA – Safety Alert</Option>
                                            <Option value='TB'>TB – Toolbox Talk</Option>
                                            <Option value='EN'>EN – Engineering Notification</Option>
                                            <Option value='QA'>QA – Quality Alert</Option>
                                            <Option value='EA'>EA – Environmental Alert</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col lg={24}>
                                    <Form.Item label='Description' name="tagPrepopulatedDiscussion" required>
                                        <TextArea
                                            value={tagPrepopulatedDiscussion}
                                            onChange={(e) => setTagPrepopulatedDiscussion(e.target.value)}
                                            size="large"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={12}>
                                    <Form.Item label='Created By' name="createdBy" required>
                                        <Input
                                            value={name}
                                            onChange={(e) => setCreatedBy(e.target.value)}
                                            size="large"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={12}>
                                    <Form.Item className="date-picker" label={isEdit ? 'Updated Date' : 'Created Date'} name="createdAt" required>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            value={createdAt ? moment(createdAt) : null}
                                            onChange={(date, dateString) => setCreatedAt(dateString)}
                                            size="large"
                                            format="DD-MMM-YYYY"
                                            disabledDate={disabledDate}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={24}>
                                    <Form.Item label='File' name="file" required>
                                        <Upload
                                            {...filePropsForOne}
                                            maxCount={1}
                                            // fileList={fileList}
                                            accept=".pdf,.txt,.doc,.pptx"
                                        >
                                            <Button disabled={disableFileUpload} icon={<UploadOutlined />}>Select File</Button>
                                        </Upload>
                                    </Form.Item>
                                    <p style={{ fontSize: "14px", color: "orangered" }}>*Supported Document Types: PDF, TXT, DOCX, PPT</p>
                                </Col>
                                {isEdit && <div>
                                    {pdfList.map((pdf, index) => (
                                        <div key={index} className="pdf-row toolbox-pdf">
                                            <p className='selectTxtFile'>{pdf.fileName}{" - "}Version {pdf.fileVersion}</p>

                                            <div className='pdf-row_button'>
                                                <Button className='rowPdfIcon' href={`https://${s3Bucketname}.s3.us-west-2.amazonaws.com/${pdf.fileKey}`} icon={<FilePdfOutlined className='IconRed' />} style={{ marginRight: '8px' }}>
                                                </Button>
                                                <Button className='rowPdfIcon' onClick={() => deleteToolListFile(pdf.id)} icon={<DeleteOutlined className='txtIcon' />} >
                                                </Button>
                                            </div>
                                        </div>
                                    ))}

                                </div>}

                            </Row>
                        </Form>


                    </Modal > : null
            }
            {
                pdfModalVisible ?
                    <Modal
                        width={500}
                        className="heatprofile-modal"
                        title={`${selectedPdfListTitle} - Available Documents`}
                        centered
                        visible={pdfModalVisible}
                        footer={[
                            <Button key="submit1" type="primary" onClick={pdfModalCancel}>
                                Cancel
                            </Button>,
                        ]}
                    >

                        <div>
                            {pdfList.map((pdf, index) => (
                                <div key={index} className="pdf-row toolbox-pdf">
                                    <p className='selectTxtFile' style={{ marginRight: '8px' }} >{pdf.fileName}{" - "}Version {pdf.fileVersion}</p>
                                    <div className='pdf-row_button'>
                                        <Button className='rowPdfIcon' href={`https://${s3Bucketname}.s3.us-west-2.amazonaws.com/${pdf.fileKey}`} icon={<FilePdfOutlined className='IconRed' />} style={{ marginRight: '8px' }}>
                                        </Button>
                                        <Button className='rowPdfIcon' onClick={() => deleteToolListFile(pdf.id)} icon={<DeleteOutlined className='txtIcon' />} >
                                        </Button>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </Modal > : null
            }
        </>

    )
}

const mapStateToProps = ({ toolBoxAdminReducer }) => {
    const toolboxList = get(toolBoxAdminReducer, 'toolBoxAdminData', []);
    const istoolboxListLoading = get(toolBoxAdminReducer, 'isFetchtoolBoxAdminDataLoading', []);
    return {
        toolboxList,
        istoolboxListLoading
    }
}

const mapDispatchToProps = {
    onFetchToolBoxList: fetchToolBoxList,
    onAddToolBox: addToolBox,
    onUpdateToolBox: updateToolBox,
    onDeleteToolBox: deleteToolBox,
    onDeleteToolBoxListFile: deleteToolBoxListFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolboxAdmin);
