/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, notification, Select, Tooltip, Typography, Upload, Row, Col, Spin } from 'antd';
import { PlusOutlined, EyeOutlined, MoreOutlined, UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import EquipmentTypes from '../../components/admin/EquipmentTypes';
import AddNewEquipmentType from '../../components/admin/AddNewEquipmentType';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchAdminEquipTypes, fetchAdminPihEquipTypes } from '../../redux/admin/equipmentTypes/action';
import { uploadEquipmentfile, copyChecks } from '../../redux/admin/upload/action';
import * as XLSX from 'xlsx';

const { Text } = Typography;
const { Option } = Select;

const Admin = (props) => {

    const { adminEquipTypesLoading, adminEquipTypes, onGetPihEquipmentTypes, onUploadEquipmentFiles, onCopyChecks, isUploadLoading, isChecksCopying } = props;




    // Maitencance types
    const [checkOpen, setChecksOpen] = useState(false);
    const [openPage, setOpenPage] = useState('');

    //equipment Types
    const [equipTypeForm] = Form.useForm();
    const [equipTypeSearchForm] = Form.useForm();

    const [openModal, setOpenModel] = useState(false);
    const [equipTypeList, setEquipTypeList] = useState([]);
    const [equipTypeListDrop, setEquipTypeListDrop] = useState([]);
    const [clear, setClear] = useState(false);
    const [paginationClear, setPaginationClear] = useState(false);
    const [equipmentType, setEquipmentType] = useState('');
    const [editModelValue, setEditModelValue] = useState('');
    const [editRecord, setEditRecord] = useState('');

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [openCopyChecksModal, setOpenCopyChecksModal] = useState(false);
    const [form] = Form.useForm();
    const [fromEquipment, setFromEquipment] = useState('');

    /** Getting Equipment Types data on load */
    useEffect(() => {
        onGetPihEquipmentTypes({
            pageSize: 20,
            pageNumber: 1
        });
    }, []);

    /** Setting the equipment type values to the state */
    useEffect(() => {
        setEquipTypeList(adminEquipTypes);
        setEquipTypeListDrop(adminEquipTypes);
    }, [adminEquipTypes])

    /** Setting Create new Equipment Model Open */
    const handleCreateEquip = () => {
        setOpenModel(true);
        setEditModelValue(false);
        setEditRecord('');
    }

    /** Getting Searched values from the selected equipment type */
    const onSearchEquipmentType = () => {

        equipTypeSearchForm.validateFields().then(async (data) => {
            if (data && data.equipmentType) {
                setClear(true);
                const newArray = adminEquipTypes.find(u => u.pihEquipmentType === data.equipmentType);
                setEquipTypeList([newArray]);
                setPaginationClear(true);
            } else {
                notification.warning({
                    message: 'Select Equipment Type to Search.'
                });
            }
        });
    }

    /** View clera button on select of equipment type */
    const onSelectEquipType = () => {
        setClear(true);
    }

    /** clearing selected data from equipment type dropdown */
    const OnclearEquipType = () => {
        setClear(false);
        equipTypeSearchForm.resetFields();
        setEquipTypeList(adminEquipTypes)
        setPaginationClear(false);
        onGetPihEquipmentTypes({
            pageSize: 10,
            pageNumber: 1
        });
        setEquipTypeListDrop(adminEquipTypes);
    }

    /** Setting Properties for upload file  */
    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            //for multiple upload
            // setFileList([...fileList, file]);
            // if (fileList.length > 0) {
            //     notification.warn({ message: 'Upload only one file at a time.' })
            // } else {
            // for single file upload
            setFileList([file]);
            setDisableFileUpload(true);
            // }
            return false;
        },
        fileList,
    };

    //read excel file
    const readExcelFile = async (file) => {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
        // Excel file validation
        const requiredHeaders = [
            "Equipment Type", "Maintenance Type", "Maintenance Interval",	
            "Unique Identifier", "Maintenance Checklist", "Photos Required", 
            "Expected Output"
        ];
        const fileHeaders = json[0];
        const hasAllHeaders = requiredHeaders.every(header => fileHeaders.includes(header));
    
        if (!hasAllHeaders) {
            notification.error({
                message: 'Invalid File Structure',
                description: `The uploaded file does not have the required headers: ${requiredHeaders.join(', ')}.`,
            });
            return [];
        }
    
        // Check if there are any data rows
        if (json.length <= 1) {
            notification.warning({
                message: 'No Data Rows',
                description: 'The uploaded file only contains headers and no data rows.',
            });
            return [];
        }
    
        const headers = [
            "equipmentType", "maintenanceType", "maintenanceInterval",	
            "uniqueIdentifier", "maintenanceChecklist", "photosRequired", 
            "expectedOutput"
        ];
        json.shift();
    
        const arrayOfObjects = json.map(row => {
            let obj = {};
            headers.forEach((header, i) => {
                obj[header] = row[i] !== undefined && row[i] !== '' ? row[i] : '';
            });
            return obj;
        });
    
        return arrayOfObjects;
    };

    /** Function to call upload file api and get **presigned Url to upload file to aws s3 bucket */
    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            const data = (await readExcelFile(fileList[0])).filter((item) => {
                const validPhotosRequiredValues = ['yes', 'no', 'if yes'];
                const validExpectedOutputValues = ['thumbs up', 'yes/no', 'value'];
    
                const isPhotosRequiredValid = validPhotosRequiredValues.includes((item.photosRequired || '').toLowerCase());
                const isExpectedOutputValid = validExpectedOutputValues.includes((item.expectedOutput || '').toLowerCase());
    
                return isPhotosRequiredValid && isExpectedOutputValid &&
                    item.maintenanceType && item.maintenanceInterval && 
                    item.uniqueIdentifier && item.maintenanceChecklist;
            });
    
            const obj = {
                globalChecks: [],
                individualEquipmentChecks: []
            };
    
            const equipmentTypeMap = {};
    
            data.forEach(item => {
                if (item.equipmentType) {
                    if (!equipmentTypeMap[item.equipmentType]) {
                        equipmentTypeMap[item.equipmentType] = [];
                    }
                    equipmentTypeMap[item.equipmentType].push(item);
                } else {
                    obj.globalChecks.push(item);
                }
            });
    
            obj.individualEquipmentChecks = Object.keys(equipmentTypeMap).map(equipmentType => ({
                equipmentType,
                data: equipmentTypeMap[equipmentType]
            }));
    
            await onUploadEquipmentFiles(obj);
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };
    

    /** Removing file and closing upload window on cancel */
    const handleCancelUploadFile = () => {
        setFileList([]);
        setUploading(false);
        setOpenUploadModal(false);
        setDisableFileUpload(false);
    }

    useEffect(() => {
        if (!isUploadLoading) {
            setFileList([]);
            setUploading(false);
            setOpenUploadModal(false);
            setDisableFileUpload(false);
        }
    }, [isUploadLoading]);

    const onSearch = (value) => {
        onGetPihEquipmentTypes({ searchKey: value })
    };

    const handleCancel=()=>{
        setOpenCopyChecksModal(false);
    }

    return (
        <div className='admin'>
            <header style={{ textAlign: 'left', height: '28px' }}>
                <div className="create_equip">
                    {/* <div className='project-details-wrapper create-quip-icon'
                        onClick={() => {
                            setOpenUploadModal(true);
                        }}
                    >
                        <Tooltip title={'Upload File'} placement='left'>
                            <CloudUploadOutlined className={`icon-28`} />
                        </Tooltip>
                    </div>

                    <div style={{ display: 'inline-flex' }}>
                        <div className='project-details-wrapper create-quip-icon'
                            onClick={handleCreateEquip}
                        >
                            <Tooltip title={'Create New Equipment Type'} placement='right'> <PlusOutlined className={`icon-28`} /></Tooltip>
                        </div>

                    </div> */}

                    <div style={{ display: 'inline-flex', height: '90px' }}>
                        <Form
                            form={equipTypeSearchForm}
                            style={{ width: '500px' }}
                        >
                            <Form.Item
                                name="equipmentType"
                                required
                            >
                                <Select
                                    className='client-select search_equip'
                                    placeholder='Type/Select Equipment Type'
                                    showSearch
                                    // allowClear
                                    optionFilterProp="children"
                                    onChange={onSelectEquipType}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={checkOpen}
                                >
                                    {equipTypeListDrop && equipTypeListDrop.map((item, i) =>
                                        <Option key={i} value={item && item.pihEquipmentType}>{item && item.pihEquipmentType}</Option>)}
                                </Select>
                            </Form.Item>
                        </Form>
                        <Button className='equipBtn' onClick={onSearchEquipmentType} style={{ marginLeft: '10px', background: checkOpen && '#EF314C', border: checkOpen && '1px solid #EF314C', color: checkOpen && '#ffffff4d' }} disabled={checkOpen}>Search</Button>
                        {clear && <Button className='equipBtn' style={{ marginLeft: '10px', background: checkOpen && '#EF314C', border: checkOpen && '1px solid #EF314C', color: checkOpen && '#ffffff4d' }} disabled={checkOpen} onClick={OnclearEquipType}>Clear</Button>}
                    </div>
                    <div className="flex-auto create_equipment">
                        <div
                            className="new-spare-data"
                        >
                            <Text onClick={handleCreateEquip} className="ps-3 add-new-spare" >
                                <span className="icon-22">
                                    <svg>
                                        <use xlinkHref="#add" />
                                    </svg>
                                </span>
                                Create New Equipment Type
                            </Text>

                            <Text onClick={() => {
                                setOpenUploadModal(true);
                            }} className="ps-3 add-new-spare">
                                <span className="icon-22">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="rotate(180)">
                                        <path d="M12 3v18M5 12l7 7 7-7"></path>
                                    </svg>

                                </span>
                                Upload File
                            </Text>

                            <Text onClick={() => {
                                setOpenCopyChecksModal(true);
                            }} className="ps-3 add-new-spare">
                                <span className="icon-22">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 1024 1024"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32M704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32M382 896h-.2L232 746.2v-.2h150z"
                                        />
                                    </svg>

                                </span>
                                Copy Checks
                            </Text>
                        </div>
                    </div>
                </div>
            </header>
            <hr />
            {/* </>
            } */}
            {/* Components */}
            <div>
                <EquipmentTypes
                    checkOpen={checkOpen}
                    setChecksOpen={setChecksOpen}
                    equipTypeList={equipTypeList}
                    setEquipTypeList={setEquipTypeList}
                    adminEquipTypesLoading={adminEquipTypesLoading}
                    setOpenModel={setOpenModel}
                    openModal={openModal}
                    equipTypeForm={equipTypeForm}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    editModelValue={editModelValue}
                    setEditModelValue={setEditModelValue}
                    setEditRecord={setEditRecord}
                    editRecord={editRecord}
                    openPage={openPage}
                    setOpenPage={setOpenPage}
                    equipTypeSearchForm={equipTypeSearchForm}
                    OnclearEquipType={OnclearEquipType}
                    clear={clear}
                    setClear={setClear}
                    paginationClear={paginationClear}
                    setPaginationClear={setPaginationClear}
                />
            </div>

            {/* Modal to create/update equipmentType */}
            <AddNewEquipmentType
                setOpenModel={setOpenModel}
                openModal={openModal}
                equipTypeForm={equipTypeForm}
                equipmentType={equipmentType}
                setEquipmentType={setEquipmentType}
                editModelValue={editModelValue}
                setEditModelValue={setEditModelValue}
                setEditRecord={setEditRecord}
                editRecord={editRecord}
            />


            <Modal
                className="pipeline-modal"
                title='Upload Equipment Data'
                centered
                visible={openUploadModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile} loading={isUploadLoading}>
                        {isUploadLoading ? 'uploading' : 'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Copy Checks"
                centered
                visible={openCopyChecksModal}
                footer={[
                    <Button key="cancel" 
                    onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" 
                    // onClick={handleOk} 
                    loading={isChecksCopying}
                    >
                        Add
                    </Button>,
                ]}
            >
                {isChecksCopying ? (
                    <Spin tip="Loading...">
                        <div style={{ minHeight: '200px' }} />Loading...
                    </Spin>
                ) : (
                <Form form={form} name="basic" layout="vertical" autoComplete="off">
                    <Row gutter={16}>
                        <Col lg={12}>
                            <Form.Item label="From Equipment" name="fromEquipment" required>
                                <Select
                                    className="client-select"
                                    placeholder="Select Equipment"
                                    onChange={(value) =>setFromEquipment(value)}
                                    value={fromEquipment}
                                    allowClear
                                    showSearch
                                    style={{ borderRadius: '5px' }}
                                >
                                     {equipTypeListDrop && equipTypeListDrop.map((item, i) =>
                                        <Option key={i} value={item && item.pihEquipmentType}>{item && item.pihEquipmentType}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item label="To Equipment" name="toEquipment" required>
                                <Select
                                    className="client-select"
                                    placeholder="Select Equipment"
                                    // onChange={handleLocationSelectChange}
                                    // value={selectedLocations}
                                    allowClear
                                    showSearch
                                    // mode="multiple"
                                    size="middle"
                                    // style={{ width: '100%' }}
                                >
                                     {equipTypeListDrop && equipTypeListDrop.filter(x=>x.pihEquipmentType!==fromEquipment).map((item, i) =>
                                        <Option key={i} value={item && item.pihEquipmentType}>{item && item.pihEquipmentType}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                )}
                <p style={{ fontSize: "14px", color: "orangered" }}>*Note: Please note that Source Equipment data will replace existing data in Destination Equipment.</p>
            </Modal>
        </div>
    )
};

const mapStateToProps = ({ adminEquipTypeReducer, uploadReducer }) => {
    const adminEquipTypes = get(adminEquipTypeReducer, 'adminEquipTypes', []);
    const adminEquipTypesLoading = get(adminEquipTypeReducer, 'adminEquipTypesLoading', false);
    const isUploadLoading = get(uploadReducer, 'isUploadLoading', false);
    const isChecksCopying = get(uploadReducer, 'isChecksCopying', false);
    return {
        adminEquipTypes,
        adminEquipTypesLoading,
        isUploadLoading,
        isChecksCopying
    }
}

const mapDispatchToProps = {
    onGetPihEquipmentTypes: fetchAdminPihEquipTypes,
    onUploadEquipmentFiles: uploadEquipmentfile,
    onCopyChecks: copyChecks,
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);