import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
  fetchEquipListSuccess,
  fetchEquipListFailure,
  addEquipDetailsSuccess,
  addEquipDetailsFailure,
  addTeamMemberSuccess,
  addTeamMemberFailure,
  fetchEquipDetailsSuccess,
  fetchEquipDetailsFailure,
  setUpdateEquipDetails,
  setUpdateEquipDetailsFailure,
  deleteEquipDetailsSuccess,
  deleteEquipDetailsFailure,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure,
  getChecksDetailsSuccess,
  getChecksDetailsFailure,
  fetchEquipMaintenanceIntervalDetailsSuccess,
  fetchEquipMaintenanceIntervalDetailsFailure,
  getChecksLogDetailsSuccess,
  getChecksLogDetailsFailure,
  getAllUsersSuccess,
  getAllUsersFailure,
  fetchEquipCategorySuccess,
  fetchEquipCategoryFailure,
  fetchTeamMembersForProjectSuccess,
  fetchTeamMembersForProjectFailure,
  getAllNonCognitoUsersSuccess,
  getAllNonCognitoUsersFailure,
  addNonCognitoUserSuccess,
  addNonCognitoUserFailure
} from './action';
import {
  ADD_EQUIPMENT_DETAILS,
  DELETE_EQUIPMENT_DETAILS,
  FETCH_EQUIPMENT_DETAILS,
  FETCH_EQUIPMENT_LIST,
  FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS,
  GET_CHECKS_DETAILS,
  UPDATE_EQUIPMENT_DETAILS,
  GET_CHECKS_LOGS_DETAILS,
  GET_ALL_USERS,
  FETCH_EQUIPMENT_CATEGORY,
  FETCH_TEAM_MEMBERS_FOR_PROJECT,
  DELETE_TEAM_MEMBER,
  ADD_TEAM_MEMBER,
  GET_ALL_NON_COGNITO_USERS,
  ADD_NON_COGNITO_USERS
} from './types';
import axiosConfig from '../../services/axiosConfig';
import adminAxiosConfig from '../../services/adminAxiosConfig';


/** Get all listed equipments */
function* fetchEquipList() {
  try {
    const res = yield axiosConfig.get('/equipments');
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchEquipListSuccess({ response }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchEquipListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the equipment list.',
      description: `${error.error}`,
    });
  }
}

/** Function to Add new Equipment Details */
function* addEquipDetails({ payload }) {
 
  try {
    //remove after deployment
    // payload.usageStatus = 1;
    const res = yield axiosConfig.post(`/pih-equipment`, payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(addEquipDetailsSuccess({ response }));
      var projectId = payload.projectId;
      var pageNumber = sessionStorage.getItem('pageNumberEquip');
      var pageSize = sessionStorage.getItem('pageSizeEquip');
      var obj = '';
      if (pageNumber && pageSize) {
        obj = { projectId, pageNumber, pageSize }
      } else {
        obj = { projectId: projectId, pageNumber: 1, pageSize: 20 }
      }
      yield fetchEquipDetails(obj);
      notification.success({
        message: 'Equipment Data Added Successfully.',
      });
    } else {
      yield put(addEquipDetailsFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(addEquipDetailsFailure());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'Selected Equipment with Sl.No. already exist!'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the details.',
        description: `${error.error}`,
      });
    }
  }
}

/** Function to Get project specific Equipment Details */
function* fetchEquipDetails(payload) {
  var url = '';
  if (payload.pageNumber && payload.pageSize) {
    sessionStorage.setItem('pageNumberEquip', payload.pageNumber);
    sessionStorage.setItem('pageSizeEquip', payload.pageSize);
    url = `/pih-equipments?projectId=${payload.projectId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`;
  } else if (payload.searchKey) {
    url = `/pih-equipments?projectId=${payload.projectId}&searchKey=${encodeURIComponent(payload.searchKey)}`
  }

  try {
    const res = yield axiosConfig.get(url);
    const response = get(res, ['data', 'data'], {});
    // notification.destroy();
    if (res.status === 200) {
      yield put(fetchEquipDetailsSuccess({ response }));
    } else {
      yield put(fetchEquipDetailsFailure());
      notification.error({
        message: 'Something went wrong. Please try again later!',
      });
    }
  } catch (error) {
    yield put(fetchEquipDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipment details.',
      description: `${error}`,
    });
  }
}

function* fetchTeamMembersForProject(payload) {
  const url = `/user-project/${payload.id}`;
  try {
    const res = yield axiosConfig.get(url);
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchTeamMembersForProjectSuccess({ response }));
    } else {
      yield put(fetchTeamMembersForProjectFailure());
      notification.error({
        message: 'Something went wrong. Please try again later!',
      });
    }
  } catch (error) {
    yield put(fetchTeamMembersForProjectFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the team members.',
      description: `${error}`,
    });
  }
}

/** Function to update Equipment Details */
function* updateEquipDetails({ data, id, projectId, searchKey }) {
  try {
    const res = yield axiosConfig.put(`/pih-equipment/${id}`, data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(setUpdateEquipDetails({ response }));
      var pageNumber = sessionStorage.getItem('pageNumberEquip');
      var pageSize = sessionStorage.getItem('pageSizeEquip');
      var obj = '';
      if (searchKey) {
        obj = { projectId, searchKey }
      } else {
        if (pageNumber && pageSize) {
          obj = { projectId, pageNumber, pageSize }
        } else {
          obj = { projectId: projectId, pageNumber: 1, pageSize: 10 }
        }
      }
      yield fetchEquipDetails(obj);
      notification.success({
        message: 'Equipment Data Updated Successfully.',
      });
    } else {
      yield put(setUpdateEquipDetailsFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(setUpdateEquipDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Updation Failed.',
      description: `${error}`,
    });
  }
}

/** Function to delete Equipment Details */
function* deleteEquipDetails({
  data
}) {
  try {
    const res = yield axiosConfig.delete(`/pih-equipment/${data.id}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(deleteEquipDetailsSuccess({ data }));
      var projectId = data.projectId;
      var pageNumber = sessionStorage.getItem('pageNumberEquip');
      var pageSize = sessionStorage.getItem('pageSizeEquip');
      var obj = '';
      if (pageNumber && pageSize) {
        obj = { projectId, pageNumber, pageSize }
      } else {
        obj = { projectId: projectId, pageNumber: 1, pageSize: 10 }
      }
      yield fetchEquipDetails(obj);
      notification.success({
        message: 'Equipment Data Deleted Successfully.',
      });
    } else {
      yield put(deleteEquipDetailsFailure());
      notification.error({
        message: 'Something went wrong. Please try again later.',
      });
    }
  } catch (error) {
    yield put(deleteEquipDetailsFailure());
    notification.destroy();
    notification.warn({
      message: error.error
    });

  }
}


/** Get all Checks Log Details (Daily/Weekly/Monthly/Quarterly/Yearly) */
function* getChecksLogDetails(data) {
  try {
    var logUrl = '';
    var pageNumber = 1; var pageSize = 10;
    if (data.pageSize && data.pageNumber) {
      pageNumber = data.pageNumber;
      pageSize = data.pageSize;
    }
    if (data && data.fromDate && data.toDate) {
      logUrl = `pihEquipmentId=${data.pihEquipmentId}&fromDate=${data.fromDate}
      &toDate=${data.toDate}&checkIntervalId=${data.checkIntervalId}&typeOfMaintenanceId=${data.typeOfMaintenanceId}
      &pageSize=${pageSize}&pageNumber=${pageNumber}`;
    } else {
      logUrl = `pihEquipmentId=${data.pihEquipmentId}&checkIntervalId=${data.checkIntervalId}
      &typeOfMaintenanceId=${data.typeOfMaintenanceId}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
    }
    const res = yield axiosConfig.get(`/pih-equipment-checks-log?${logUrl}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(getChecksLogDetailsSuccess({ response }));
    } else {
      yield put(getChecksLogDetailsFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(getChecksLogDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Checks Log Details.',
      description: `${error}`,
    });
  }
}

function* getAllCognitoUsers() {
  try {
    const res = yield axiosConfig.get(`/cognito-users-list`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(getAllUsersSuccess({ response }));
    } else {
      yield put(getAllUsersFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(getAllUsersFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Checks Log Details.',
      description: `${error}`,
    });
  }
}

/** Get all Checks Details (Daily/Weekly/Monthly/Quarterly/Yearly) */
function* getChecksDetails(data) {
  try {
    //tod
    const res = yield axiosConfig.get(`/pih-equipment-checks-log-detail?pihEquipmentCheckDetailId=${data.pihEquipmentCheckDetailId}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(getChecksDetailsSuccess({ response }));
    } else {
      yield put(getChecksDetailsFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(getChecksDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Checks Details.',
      description: `${error}`,
    });
  }
}


/** Function to Get project specific Equipment's Maintenance types and Interval Details */
function* fetchEquipMaintenanceIntervalDetails({ id }) {
 
  try {
    const res = yield axiosConfig.get(`/pih-equipment/maintenance-types-and-intervals?pihEquipmentId=${id}`);
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchEquipMaintenanceIntervalDetailsSuccess({ response }));
    } else {
      yield put(fetchEquipMaintenanceIntervalDetailsFailure());
      notification.error({
        message: 'Something went wrong. Please try again later!',
      });
    }
  } catch (error) {
    yield put(fetchEquipMaintenanceIntervalDetailsFailure());
    notification.destroy();
    notification.error({
      message: "A problem occured while fetching the Equipment's Maintenance and Interval details.",
      description: `${error}`,
    });
  }
}


/** Function to Get Equipment Category Details */
function* fetchEquipCategory() {
  try {
    // const res = yield axiosConfig.get(`/pih-equipment-type`);
    const res = yield adminAxiosConfig.get(`/equipment-types?exclude=createdAt&exclude=updatedAt&exclude=inputPower&exclude=outputPower&exclude=voltage&exclude=frequency&exclude=phase&exclude=airConsumption&exclude=operatingTemperatureRange&exclude=equipmentSpecific&exclude=dimensions&exclude=weight&exclude=productHirerachyId&exclude=rentalGroupId`);
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchEquipCategorySuccess({ response }));
    } else {
      yield put(fetchEquipCategoryFailure());
      notification.error({
        message: 'Something went wrong. Please try again later!',
      });
    }
  } catch (error) {
    yield put(fetchEquipCategoryFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipment Category.',
      description: `${error}`,
    });
  }
}

function* deleteTeamMember({data}) {
  try {
    const res = yield axiosConfig.delete(`user-project/${data.teamMemberData.id}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(deleteTeamMemberSuccess({ response }));
      yield fetchTeamMembersForProject(data.selectedProject);
      notification.success({
        message: 'Team Member Data Deleted Successfully.',
      });
    } else {
      yield put(deleteTeamMemberFailure());
      notification.error({
        message: 'Something went wrong. Please try again later.',
      });
    }
  } catch (error) {
    yield put(deleteTeamMemberFailure());
    notification.destroy();
    notification.warn({
      message: error.error
    });

  }
}

function* addTeamMember({ payload }) {
  try {
    const res = yield axiosConfig.post(`/user-project`, payload.addMemberPayload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {      
      yield fetchTeamMembersForProject(payload.selectedProject);
      notification.success({
        message: 'Team Member Added Successfully.',
      });
      yield put(addTeamMemberSuccess({ response }));
    } else {
      yield put(addTeamMemberFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(addTeamMemberFailure());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'Team Member already exist!'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the details.',
        description: `${error.error}`,
      });
    }
  }
}

/*Get All non Cognito Users*/

function* getAllNonCognitoUsers() {
  try {
    const res = yield axiosConfig.get(`/users`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(getAllNonCognitoUsersSuccess({ response }));
    } else {
      yield put(getAllNonCognitoUsersFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(getAllNonCognitoUsersFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Checks Log Details.',
      description: `${error}`,
    });
  }
}

/*Add non cognito user*/

function* addNonCognitoUser({ payload }) {
  try {
    const res = yield axiosConfig.post(`/user`, payload.addNonCognitoPayload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {      
      yield getAllCognitoUsers(payload.selectedProject);
      yield getAllNonCognitoUsers()
      notification.success({
        message: 'User Added Successfully.',
      });
      yield put(addNonCognitoUserSuccess({ response }));
    } else {
      yield put(addTeamMemberFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(addNonCognitoUserSuccess());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'User already exist!'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the details.',
        description: `${error.error}`,
      });
    }
  }
}

function* sowWatcher() {
  yield takeLatest(FETCH_EQUIPMENT_LIST, fetchEquipList);
  yield takeLatest(ADD_EQUIPMENT_DETAILS, addEquipDetails);
  yield takeLatest(FETCH_EQUIPMENT_DETAILS, fetchEquipDetails);
  yield takeLatest(UPDATE_EQUIPMENT_DETAILS, updateEquipDetails);
  yield takeLatest(DELETE_EQUIPMENT_DETAILS, deleteEquipDetails);
  yield takeLatest(GET_CHECKS_DETAILS, getChecksDetails);
  yield takeLatest(FETCH_EQUIPMENT_MAINTENANCE_INTERVAL_DETAILS, fetchEquipMaintenanceIntervalDetails);
  yield takeLatest(GET_CHECKS_LOGS_DETAILS, getChecksLogDetails);
  yield takeLatest(FETCH_EQUIPMENT_CATEGORY, fetchEquipCategory);
  yield takeLatest(GET_ALL_USERS, getAllCognitoUsers);
  yield takeLatest(FETCH_TEAM_MEMBERS_FOR_PROJECT, fetchTeamMembersForProject);
  yield takeLatest(DELETE_TEAM_MEMBER, deleteTeamMember);
  yield takeLatest(ADD_TEAM_MEMBER, addTeamMember);
  yield takeLatest(GET_ALL_NON_COGNITO_USERS, getAllNonCognitoUsers);
  yield takeLatest(ADD_NON_COGNITO_USERS, addNonCognitoUser);
}

function* equipmentSagas() {
  yield all([
    call(sowWatcher),
  ]);
}

export default equipmentSagas;