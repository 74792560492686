/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Skeleton, Table, Tooltip, Typography } from 'antd';
import {
    MoreOutlined,
    RightCircleOutlined,
    ArrowLeftOutlined,
    EditOutlined,
    DeleteOutlined,
    SettingOutlined
} from '@ant-design/icons';
import MaintenanceTypes from './MaintenanceTypes';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { deleteAdminEquipTypes, fetchAdminEquipTypes, fetchAdminPihEquipTypes } from '../../redux/admin/equipmentTypes/action';
import EquipmentsNo from './EquipmentsNo';
import { fetchAdminEquipSno, fetchAdminEquipTypeSno, fetchAdminEquipSubTypeSno, fetchAdminEquipDocType } from '../../redux/admin/equipmentSno/action';
import EquipSubCategory from './EquipSubCategory';
import DocumentList from './DocumentList';

const { Text } = Typography;

const DocEquipmentTypes = (props) => {
    const {
        openPage, setOpenPage,
        setOpenModel, openModal,
        checkOpen, setChecksOpen,
        equipTypeList, setEquipTypeList,
        adminEquipTypesLoading,
        equipmentType, setEquipmentType,
        equipTypeForm,
        editModelValue,
        setEditModelValue,
        setEditRecord,
        editRecord,
        OnclearEquipType,

        onDeleteEquipType,
        isEquipTypeDeleteLoading,
        adminEquipTypeSnoData,
        adminEquipTypeSnoLoad,
        onGetEquipSno,
        equipTypeSearchForm,
        clear,
        setClear,
        adminEquipTypesTotalCount,
        onGetPihEquipmentTypes,
        paginationClear,
        setPaginationClear,
        onFetchAdminEquipSubType,
        onFetchAdminEquipDocType,
        equipmentFormData,
        //
        setDocumentId,
        documentId,
        equipmentSubTypeId,
        setEquipmentSubTypeId,
        handleSetForm,
        setSubCategoryPageActive,
        setDocumentPageActive
    } = props;

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteEquipId, setDeleteEquipId] = useState('');
    const [equipmentTypeId, setEquipmentTypeId] = useState();
    const [equipmentTypeName, setEquipmentTypeName] = useState();
    const [equipmentSubTypeName, setEquipmentSubTypeName] = useState();
    const [openSubEquip, setOpenSubEquip] = useState('');
    const [closeDocList, setCloseDocList] = useState('');
    const [prevClickCount, setPrevClickCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);


    const typesListColumns = [
        {
            title: 'Equipment Category',
            dataIndex: 'equipmentTypeCategory',
            key: 'equipmentTypeCategory',
            align: 'left',
            // sorter: (a, b) => {return (a-b)},
            ellipsis: true,
        },
        // {
        //     title: 'Actions',
        //     key: 'action',
        //     dataIndex: 'action',
        //     align: 'center',
        //     width: '150px',
        //     render: (text, record) => {
        //         return (
        //             <div className='tableIcons'>
        //                 <Tooltip title='Update' placement='left'>
        //                     <EditOutlined className='icon-18' style={{ margin: '0 10px', color: '#ef314c' }}
        //                         onClick={() => setEditModel(record)}
        //                     /></Tooltip>
        //                 <Tooltip title='Delete' placement='top'>  <DeleteOutlined className='icon-18' style={{ margin: '0 10px', color: '#ef314c' }}
        //                     onClick={() => {
        //                         setDeleteModal(true);
        //                         setDeleteEquipId(record);
        //                     }}
        //                 /></Tooltip>
        //             </div>
        //         );
        //     },
        //     align: 'center'
        // },
        // {
        //     key: 'action',
        //     dataIndex: 'action',
        //     align: 'center',
        //     width: '250px',
        //     render: (text, record) => {
        //         return (
        //             <div className='tableIcons'>
        //                 <Button className='maintenance_mng_Btn' onClick={() => {
        //                     setChecksOpen(true);
        //                     setOpenPage('maintenance');
        //                     // setEquipmentTypeId(record.id);
        //                     setEquipmentTypeName(record.pihEquipmentType);
        //                     equipTypeSearchForm.setFieldsValue({ equipmentType: record.pihEquipmentType });
        //                 }}
        //                 >Maintenance Management</Button>
        //             </div>
        //         );
        //     },
        //     align: 'center'
        // },
        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            width: '100px',
            render: (text, record) => {
                return (
                    <div className='tableIcons'>
                        <Tooltip title='Equip Sub Type' placement='top'>
                            <RightCircleOutlined className='icon-22'
                                onClick={async () => {
                                    setChecksOpen(true);
                                    setOpenPage('equipSubCategory');
                                    equipTypeSearchForm.setFieldsValue({ equipmentType: record.equipmentTypeCategory });
                                    setEquipmentTypeName(record.equipmentTypeCategory);
                                    setEquipmentTypeId(record.id);
                                    setSubCategoryPageActive(true);
                                    // equipmentFormData.setFieldsValue({ pihEquipment: record.pihEquipmentType });
                                    // console.log(record)
                                    await onFetchAdminEquipSubType({ pihEquipmentTypeCategory: record.equipmentTypeCategory });
                                }} /></Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
    ];

    /** Setting values for updating equipment types */
    const setEditModel = (record) => {
        setOpenModel(true);
        setEditRecord(record);
        setEditModelValue(true);
    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setDeleteModal(false);
        setDeleteEquipId('');
    }

    /** Waiting to delete equipment type and close Modal */
    useEffect(() => {
        if (!isEquipTypeDeleteLoading) {
            setDeleteModal(false);
            setDeleteEquipId('');
            // OnclearEquipType();
            setClear(false);
            equipTypeSearchForm.resetFields();
            setPaginationClear(false);
        }
    }, [isEquipTypeDeleteLoading]);

    return (
        <div>
            {!checkOpen ? <div>
                <Row gutter={24}>
                    <Col span={24} style={{ padding: '0px 12px 0' }}>
                        {/* <Skeleton active loading={adminEquipTypesLoading} > */}
                        <Table
                            className='masterEquipTable_Pagination'
                            columns={typesListColumns}
                            dataSource={[...equipTypeList]}
                            scroll={{ x: '100%', y: '100%' }}
                            // pagination={true}
                            pagination={{
                                position: ['bottomRight'],
                                showSizeChanger: true,  // Allows selecting different page sizes
                                total: equipTypeList.length,
                                current: current,      // Controlled by state
                                pageSize: pageSize,    // Controlled by state
                                onChange: (page, newPageSize) => {
                                    setCurrent(page);
                                    setPageSize(newPageSize);  // Update page size dynamically
                                },
                            }}
                            loading={adminEquipTypesLoading}
                        />
                        {/* </Skeleton> */}
                    </Col>
                </Row>
            </div>
                :
                <div>
                    <div className='back_equip'>
                        {openPage == 'documentList' ?
                            <Tooltip title={' Back'}> <ArrowLeftOutlined onClick={() => {
                                setOpenPage('equipSubCategory');
                                setDocumentPageActive(false);
                            }} /></Tooltip> :
                            <Tooltip title={' Back'}> <ArrowLeftOutlined onClick={() => {
                                setChecksOpen(false);
                                setOpenPage('');
                                if (!clear) {
                                    equipTypeSearchForm.resetFields();
                                    OnclearEquipType();
                                    setEquipmentTypeName('');
                                    setSubCategoryPageActive(false);
                                    equipmentFormData.resetFields(['pihCategory', 'pihEquipment']);
                                }
                            }} /></Tooltip>
                        }
                    </div>
                    {openPage === 'maintenance' ?
                        <div style={{ marginTop: '-20px' }}>
                            <MaintenanceTypes
                                equipmentTypeId={equipmentTypeId}
                                equipmentTypeName={equipmentTypeName}
                            />
                        </div>
                        : openPage === 'equipSubCategory' ?
                            <div>
                                <EquipSubCategory
                                    setOpenPage={setOpenPage}
                                    equipmentTypeId={equipmentTypeId}
                                    equipmentTypeName={equipmentTypeName}
                                    setEquipmentSubTypeName={setEquipmentSubTypeName}
                                    setEquipmentSubTypeId={setEquipmentSubTypeId}
                                    equipmentFormData={equipmentFormData}
                                    handleSetForm={handleSetForm}
                                    setDocumentPageActive={setDocumentPageActive}
                                />
                            </div>
                            :
                            <div style={{ marginTop: '12px' }}>
                                <DocumentList
                                    equipmentTypeId={equipmentTypeId}
                                    equipmentSubTypeId={equipmentSubTypeId}
                                    documentId={documentId}
                                    setDocumentId={setDocumentId}
                                    equipmentSubTypeName={equipmentSubTypeName}
                                    equipmentTypeName={equipmentTypeName}
                                    handleSetForm={handleSetForm}
                                />
                                {/* <EquipmentsNo
                                    adminEquipTypeSnoData={adminEquipTypeSnoData}
                                    adminEquipTypeSnoLoad={adminEquipTypeSnoLoad}
                                    equipmentTypeName={equipmentTypeName}
                                    equipmentTypeId={equipmentTypeId}
                                    setEquipmentTypeId={setEquipmentTypeId}
                                /> */}
                            </div>
                    }
                </div>
            }
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Equipment Type"
                centered
                visible={deleteModal}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={() => onDeleteEquipType({ pihEquipmentType: deleteEquipId.pihEquipmentType })} loading={isEquipTypeDeleteLoading}>
                        {isEquipTypeDeleteLoading ? 'Deleting' : 'Confirm'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}><p>Deleting Equipment Type will delete all the corresponding Equipments and related information.</p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{deleteEquipId && deleteEquipId.pihEquipmentType}</b> Equipment Type?</Text>
                </div>
            </Modal>

        </div>
    )
}

const mapStateToProps = ({ adminEquipTypeReducer, adminEquipSnoReducer }) => {
    const adminEquipTypes = get(adminEquipTypeReducer, 'adminEquipTypes', []);
    const adminEquipTypesTotalCount = get(adminEquipTypeReducer, 'adminEquipTypesTotalCount', null);
    const isEquipTypeDeleteLoading = get(adminEquipTypeReducer, 'isEquipTypeDeleteLoading', false);
    const adminEquipTypeSnoLoad = get(adminEquipSnoReducer, 'adminEquipTypeSnoLoad', false);
    const adminEquipTypeSnoData = get(adminEquipSnoReducer, 'adminEquipTypeSnoData', []);
    return {
        adminEquipTypes,
        isEquipTypeDeleteLoading,
        adminEquipTypeSnoData,
        adminEquipTypeSnoLoad,
        adminEquipTypesTotalCount
    }
}

const mapDispatchToProps = {
    onDeleteEquipType: deleteAdminEquipTypes,
    onGetEquipSno: fetchAdminEquipTypeSno,
    onGetPihEquipmentTypes: fetchAdminPihEquipTypes,
    onFetchAdminEquipSubType: fetchAdminEquipSubTypeSno,
    onFetchAdminEquipDocType: fetchAdminEquipDocType,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocEquipmentTypes);
