import React, { useEffect, useState } from 'react';
import { Typography, Modal, notification, Button, Upload, Form, Tooltip, DatePicker, Col, Row, Input, message, Spin, Table, Select } from 'antd';
import { ImportOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import RatesGridModal from './RatesGridModal';
import RechargesheetExpand from './RechargesheetExpand';
import { fetchRechargeList, fetchRechargeChargeList, addRecharge, updateRecharge, deleteRecharge } from '../redux/billing/action'
import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option } = Select;

const RechargeSheet = (props) => {
    const { selectedProject, selectedMonthAndYear, isRechargeModalVisible, setIsRechargeModalVisible, RechargeTitle, setRechargeTitle, rechargeModalVisible, setRechargeModalVisible, rechargeData, setRechargeData, ratesModalVisible, setRatesModalVisible, setRechargeExcelUrl,
        //
        onFetchRechargeList, onFetchRechargeChargeList, onAddRecharge, onUpdateRecharge, onDeleteRecharge, rechargeDataList, chargeListData, isRechargeDataListLoading, isChargeListDataLoading
    } = props;

    const [activityType, setActivityType] = useState('')
    const [rechargeRowId, setRechargeRowId] = useState('')
    const [userForm] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (selectedProject) {
            onFetchRechargeList({ projectId: selectedProject.id, monthAndYear: selectedMonthAndYear });
        }
    }, [selectedProject, selectedMonthAndYear])
    //
    useEffect(() => {
        if (rechargeDataList && rechargeDataList.length) {
            setRechargeData(rechargeDataList)
            setRechargeExcelUrl(rechargeDataList[0]?.reportDetails?.fileUrl)
        } else {
            setRechargeData([])
            setRechargeExcelUrl('')
        }
    }, [rechargeDataList])

    //
    useEffect(() => {
        if (activityType) {
            onFetchRechargeChargeList({ projectId: selectedProject.id, activityType: activityType })
        }
    }, [activityType])

    useEffect(() => {
        if (chargeListData && chargeListData.length) {
            const chargeValue = chargeListData.filter(item => item.activityType == activityType)
            userForm.setFieldsValue({ chargeRate: chargeValue[0]?.fixedAndVariablePriceOcrcy });
        }
        handleValuesChange()
    }, [chargeListData])


    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }

    function getMonthWeekNumber(date) {
        const currentDate = new Date(date);
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const dayOfMonth = currentDate.getDate();
        const weekNumber = Math.ceil(dayOfMonth / 7);
        return weekNumber;
    }

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            width: '60px',
            align: 'left',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Wk No',
            dataIndex: 'rechargeDate',
            key: 'rechargeDate',
            width: '60px',
            align: 'center',
            render: (text) => getMonthWeekNumber(text),
        },
        {
            title: 'Date',
            dataIndex: 'rechargeDate',
            key: 'rechargeDate',
            align: 'center',
            render: (text) => moment(text).format("DD-MM-YYYY"),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            align: 'center',
        },
        {
            title: 'Employer',
            dataIndex: 'employer',
            key: 'employer',
            align: 'center',
        },
        {
            title: 'WBS/ WBS/ COST CENTRE',
            dataIndex: 'wbsCostCenter',
            key: 'wbsCostCenter',
            align: 'center',
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Activity Type',
            dataIndex: 'activityType',
            key: 'activityType',
            align: 'center',
        },
        {
            title: 'Charge Rate',
            dataIndex: 'chargeRate',
            key: 'chargeRate',
            align: 'center',
            render: (text) => `$${text?.toFixed(2)}`,
        },
        {
            title: 'Daily Conversion',
            dataIndex: 'dailyConversion',
            key: 'dailyConversion',
            align: 'center',
            render: (text) => `${text?.toFixed(2)}`,
        },
        {
            title: 'Recharge',
            dataIndex: 'recharge',
            key: 'recharge',
            align: 'center',
            render: (text) => `$${text?.toFixed(2)}`,
        },
        {
            title: 'Employee ID',
            dataIndex: 'employeeId',
            key: 'employeeId',
            align: 'center',
        },
        {
            title: 'Employee or Contractor',
            dataIndex: 'employeeType',
            key: 'employeeType',
            align: 'center',
        },
        {
            title: 'Sender Cost Center',
            dataIndex: 'senderCostCenter',
            key: 'senderCostCenter',
            align: 'center',
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
                <div>
                    <Tooltip title="Edit">
                        <EditOutlined className='txtIcon' style={{ marginLeft: '10px', fontSize: '18px' }}
                            onClick={() => showAddEditListModal(record, true)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteOutlined className='txtIcon' style={{ marginLeft: '10px', fontSize: '18px' }}
                            onClick={() => handleDeletion(record)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    //Edit rates list data
    const showAddEditListModal = async (record, edit) => {
        if (record !== '') {
            record.rechargeDate = moment(record.rechargeDate);
            userForm.setFieldsValue(record);
            setRechargeRowId(record.id);
        }
        setRechargeTitle('Update Recharge');
        setIsEdit(edit);
        setIsRechargeModalVisible(true);
    };

    //Delete List data
    const handleDeletion = (record) => {
        Modal.confirm({
            className: 'custom-text-clr',
            title: 'Are you sure you want to delete this data?',
            async onOk() {
                try {
                    onDeleteRecharge({ id: record.id, projectId: selectedProject.id, monthAndYear: selectedMonthAndYear })
                } catch (error) {
                    console.error('Error deleting data:', error);
                    message.error('Data delete failed.');
                    throw error;
                }
            },
            onCancel() { },
        });
    };


    //handle to add and update recharge
    const handleOk = () => {
        const data = userForm.getFieldsValue();
        if (
            !data.rechargeDate ||
            !data.name?.trim() ||
            !data.role?.trim() ||
            !data.employer?.trim() ||
            !data.wbsCostCenter?.trim() ||
            !data.activityType?.trim() ||
            data.chargeRate == null ||
            data.dailyConversion == null ||
            data.recharge == null ||
            !data.employeeId?.trim() ||
            !data.employeeType?.trim() ||
            !data.senderCostCenter?.trim()
        ) {
            notification.warning({
                message: 'Fill all the required fields!'
            });
            return;
        }

        const payload = {
            projectId: selectedProject.id,
            monthAndYear: selectedMonthAndYear,
            rechargeDate: data.rechargeDate,
            name: data.name.trim(),
            role: data.role.trim(),
            employer: data.employer.trim(),
            wbsCostCenter: data.wbsCostCenter.trim(),
            activityType: data.activityType.trim(),
            chargeRate: data.chargeRate,
            dailyConversion: data.dailyConversion,
            recharge: data.recharge,
            employeeId: data.employeeId.trim(),
            employeeType: data.employeeType.trim(),
            senderCostCenter: data.senderCostCenter.trim(),
        };

        if (isEdit) {
            payload.id = rechargeRowId;
            onUpdateRecharge({ data: { recharge: payload }, id: rechargeRowId, monthAndYear: selectedMonthAndYear });
        } else {
            onAddRecharge({ data: payload, monthAndYear: selectedMonthAndYear });
        }
        resetFields();
    };

    const resetFields = () => {
        setTimeout(() => {
            setRechargeRowId('')
            userForm.resetFields();
            setIsEdit(false);
            setIsRechargeModalVisible(false);
        }, 1000);
    };

    const handleCancel = () => {
        setRechargeRowId('')
        userForm.resetFields();
        setIsEdit(false);
        setIsRechargeModalVisible(false);
    };

    const handleValuesChange = (changedValues) => {

        const values = userForm.getFieldsValue();
        const { chargeRate, dailyConversion } = values;

        // Compute the sum and update the form
        const fixedChargeRate = parseFloat(chargeRate) || 0;
        const fixedDailyConversion = parseFloat(dailyConversion) || 0;

        const total = fixedChargeRate * fixedDailyConversion;
        userForm.setFieldsValue({ recharge: total });
    };

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div style={{ width: "100%", overflowX: "auto" }}>
                {
                    (rechargeData && rechargeData.length) ?
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={rechargeData}
                            className="h-100 recharge-table heatprofile_table"
                            scroll={{ x: rechargeData.length > 0 ? 2500 : 0, y: 'calc(100vh - 250px)' }}
                            loading={{
                                indicator: <div><Spin /> Loading...</div>,
                                spinning: isRechargeDataListLoading
                            }}
                        /> :
                        <Table
                            pagination={false}
                            loading={{
                                indicator: <div><Spin /> Loading...</div>,
                                spinning: isRechargeDataListLoading
                            }}
                        />
                }
            </div>
            <Modal
                width={1000}
                className="toolbox-admin"
                title={RechargeTitle}
                centered
                visible={isRechargeModalVisible}
                footer={isEdit ? [
                    <Button key="submit1" type="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button key="submit2"
                        type='primary'
                        loading={isRechargeDataListLoading}
                        onClick={handleOk}
                    >
                        {isRechargeDataListLoading ? "Updating" : "Update"}
                    </Button>
                ] : [
                    <Button key="submit3" type="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit4"
                        size="medium"
                        shape="round"
                        type='primary'
                        loading={isRechargeDataListLoading}
                        onClick={handleOk}
                    >
                        {isRechargeDataListLoading ? "Creating" : "Create"}
                    </Button>
                ]}
            >
                <Spin spinning={isChargeListDataLoading}>
                <Form
                    name="employeeForm"
                    layout="vertical"
                    autoComplete="off"
                    form={userForm}
                        initialValues={{ dailyConversion: 12 }}
                        onValuesChange={handleValuesChange}
                >
                    <Row gutter={12} className="upload-row">
                        <Col lg={6}>
                            <Form.Item
                                label="Recharge Date"
                                name="rechargeDate"
                                className="date-picker"
                                required
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Name"
                                name="name"
                                required
                            >
                                <Input
                                    placeholder="Enter Name"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Role"
                                name="role"
                                required
                            >
                                <Input
                                    placeholder="Enter Role"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Employer"
                                name="employer"
                                required
                            >
                                <Input
                                    placeholder="Enter Employer"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="WBS/Cost Center"
                                name="wbsCostCenter"
                                required
                            >
                                <Input
                                    placeholder="Enter WBS/Cost Center"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Activity Type"
                                name="activityType"
                                required
                            >
                                <Input
                                    placeholder="Enter Activity Type"
                                        onChange={(e) => setActivityType(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Charge Rate"
                                name="chargeRate"
                                required
                            >
                                <Input
                                    placeholder="Enter Charge Rate"
                                    size="large"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Daily Conversion"
                                name="dailyConversion"
                                required
                            >
                                <Input
                                    placeholder="Enter Daily Conversion"
                                    size="large"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Recharge"
                                name="recharge"
                                required
                            >
                                <Input
                                    placeholder="Enter Recharge"
                                    size="large"
                                    style={{ width: '100%' }}
                                        disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Employee ID"
                                name="employeeId"
                                required
                            >
                                <Input
                                    placeholder="Enter Employee ID"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Employee Type"
                                name="employeeType"
                                required
                            >
                                <Input
                                    placeholder="Enter Employee Type"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Sender Cost Center"
                                name="senderCostCenter"
                                required
                            >
                                <Input
                                    placeholder="Enter Sender Cost Center"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                </Spin>
            </Modal >
            {rechargeModalVisible &&
                <RechargesheetExpand
                columns={columns}
                rechargeData={rechargeData}
                isRechargeDataListLoading={isRechargeDataListLoading}
                setIsRechargeModalVisible={setIsRechargeModalVisible}
                setRechargeModalVisible={setRechargeModalVisible}
                />
            }
            {ratesModalVisible &&
                <RatesGridModal
                    setRatesModalVisible={setRatesModalVisible}
                    selectedProject={selectedProject}
                />
            }
        </div>
    );
}
const mapStateToProps = ({ billingDashBoardReducer }) => {
    const rechargeDataList = get(billingDashBoardReducer, 'rechargeList', []);
    const chargeListData = get(billingDashBoardReducer, 'rechargeChargeList', []);
    const isRechargeDataListLoading = get(billingDashBoardReducer, 'isRechargeListLoading', []);
    const isChargeListDataLoading = get(billingDashBoardReducer, 'isRechargeChargeListLoading', []);
    return {
        rechargeDataList,
        chargeListData,
        isRechargeDataListLoading,
        isChargeListDataLoading
    }
}

const mapDispatchToProps = {
    onFetchRechargeList: fetchRechargeList,
    onFetchRechargeChargeList: fetchRechargeChargeList,
    onAddRecharge: addRecharge,
    onUpdateRecharge: updateRecharge,
    onDeleteRecharge: deleteRecharge,
}

export default connect(mapStateToProps, mapDispatchToProps)(RechargeSheet);