/* eslint-disable no-const-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable dot-notation */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import {
  Button, Table, Form, Modal, Spin, notification, Tooltip, Typography, Input
} from 'antd';
const { Title, Text } = Typography;
import {
  DeleteOutlined, EditOutlined, UnlockOutlined, ArrowDownOutlined, ArrowUpOutlined
} from '@ant-design/icons';
import AddUserModal from '../../components/AddUserModal';
import { getUsers, getAccess } from '../../services/teamMembers';
import {
  saveUsers, removeAccess, resetUserPassword, sendUserEmail, addAccess, removeUser
} from '../../services/userManagement';
import ResetPasswordModal from "../../components/ResetPasswordModal";

const UserManagementPage = () => {
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [userModalLoading, setUserModalLoading] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [resetPasswordModalLoading, setResetPasswordModalLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [disableSendEmail, setDisableSendEmail] = useState(false);
  const [teamModalLoading, setTeamModalLoading] = useState(false);
  const [createUserLoader, setCreateUserLoader] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);

  const { Column, ColumnGroup } = Table;

  const [userForm] = Form.useForm();
  const [resetPasswordForm] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTokens, setPageTokens] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  const [resetPasswordRecord, setResetPasswordRecord] = useState([]);
  const [searchKeyExist, setSearchKeyExist] = useState('');
  const [maxmUsersPerPage, setMaxUsersPerPage] = useState(0);


  const columns = [
    //   {
    //     title: 'S.No',
    //     dataIndex: 'serialNo',
    //     key: 'serialNo',
    //     align: 'center',
    //     width: '65px',
    //     render: (text, record, index) => index + 1,
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '300px'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      width: '410px'
    },
    {
      title: 'Confirmation status',
      dataIndex: 'userStatus',
      key: 'userStatus',
      align: 'left',
      width: '300px'
    },
    {
      title: 'Action',
      key: 'actions',
      render: (record) => {
        if (record) {
          return (
            <div>
              <Tooltip
                arrowPointAtCenter
                placement="topRight"
                title="Edit User Access"
              >
                <EditOutlined className='heatIcon' style={{ marginRight: '30px', fontSize: '16px' }} onClick={() => showAddEditUserModal(record, true)} />
              </Tooltip>
              <Tooltip
                arrowPointAtCenter
                placement="topRight"
                title="Reset User Password"
              >
                <UnlockOutlined className='heatIcon' style={{ marginRight: '30px', fontSize: '16px' }} onClick={() => showResetPasswordModal(record, true)} />
              </Tooltip>
              <Tooltip
                arrowPointAtCenter
                placement="topLeft"
                title="Delete User"
              >
                <DeleteOutlined className='heatIcon' style={{ marginRight: '30px', fontSize: '16px' }} onClick={() => handleDeletion(record)} />
              </Tooltip>
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
      align: 'center'
    }
  ];


  const getUsersList = async (pageToken, pageAction, searchKeyExist) => {
    setShowLoader(true);
    const response = await getUsers(pageToken, searchKeyExist);
    if (response && response.users.length) {
      setPageTokens([...pageTokens, response.paginationToken]);
      setUserCount(response.count);
      // eslint-disable-next-line consistent-return
      let usersForProject = response.users.map((obj) => {
        const details = { user_id: obj.Username };
        obj.Attributes.forEach((element) => {
          if (element.Name === 'custom:first_name') {
            details.userFirstName = element.Value;
          } else if (element.Name === 'custom:last_name') {
            details.userLastName = element.Value;
          } else if (element.Name === 'email') {
            details.email = element.Value;
          }
        });
        details.userLastName ? details.name = details.userFirstName + " " + details?.userLastName : details.name = details.userFirstName;
        details.userStatus = obj.UserStatus;
        details.enabled = obj.Enabled;
        if (details.name && details.email && details.userStatus && details.enabled) {
          return details;
        }
      });
      usersForProject = usersForProject.filter((el) => el);
      usersForProject.sort((a, b) => a.name.localeCompare(b.name));
      setUserData(usersForProject);
      if (maxmUsersPerPage === 0) {
        setMaxUsersPerPage(usersForProject.length)
      }
      if (pageAction == "next") {
        setTotalUsers(totalUsers + usersForProject.length);
      } else if (pageAction == "prev") {
        setTotalUsers(totalUsers - usersForProject.length);
      } else {
        setTotalUsers(usersForProject.length);
      }
      setShowLoader(false);
    } else if (response.users.length == 0) {
      // notification.warning({
      //   message: 'User does not exist with the given email.',
      //   duration: 1.2
      // });
      console.log("User does not exist with the given email");
      setUserData(null);
      setShowLoader(false);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  useEffect(() => {
    getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showResetPasswordModal = (record) => {
    setResetPasswordRecord(record);
    setResetPasswordModalVisible(true);
  };

  const handleDeletion = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this user?',
      className: 'custom-text-clr',
      async onOk() {
        return removeUser(record.user_id, record.email).then((res) => {
          if (res) {
            notification.success({
              message: 'User deleted successfully',
            });
            getUsersList();
          } else {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() { },
    });
  };

  const saveUser = async (data) => {
    setUserModalLoading(false);
    const response = await saveUsers(data);
    if (response === 200) {
      setDisableSendEmail(true);
      notification.success({
        message: 'User created successfully and credentials sent to corresponding email address',
        duration: 1.2
      });
      getUsersList();
    } else {
      notification.warning({
        message: 'User with the given email already exists.',
        duration: 1.2
      });
      setCreateUserLoader(false);
    }
    userForm.resetFields();
    setCreateUserLoader(false);
    setDisableSendEmail(false);
    setUserModalVisible(false);
  };

  const sendEmail = async (data) => {
    const response = await sendUserEmail(data);
    if (response) {
      notification.success({
        message: 'Email sent successfully',
      });
      userForm.resetFields();
      setCreateUserLoader(false);
      setDisableSendEmail(false);
      setUserModalVisible(false);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const resetPassword = async (data) => {
    resetPasswordForm.resetFields();
    const response = await resetUserPassword(data);
    if (response) {
      notification.success({
        message: 'Password has been reset successfully',
      });
    } else {
      notification.success({
        message: 'Something went wrong',
      });
    }
    setResetPasswordModalLoading(false);
    setResetPasswordModalVisible(false);
  };

  const showAddEditUserModal = async (record, edit) => {
    let userDt = {};
    if (record !== '') {
      const response = await getAccess(record.user_id);
      console.log(response)
      const userAccess = response.map(item => item?.GroupName)
      const fullName = record.name.split(" ");
      const fName = fullName[0];
      if (fullName.length === 2) {
        const lName = fullName[1];
        userDt = {
          email: record.email,
          firstName: fName,
          lastName: lName,
          access: userAccess
        };
      } else {
        userDt = {
          email: record.email,
          firstName: fName,
          access: userAccess
        };
      }
      userForm.setFieldsValue(userDt);
      setUserName(record.user_id);
      setUserGroup(userAccess);
      console.log(userAccess)
    } else {
      userForm.resetFields();
    }

    setIsEdit(edit);

    setUserModalVisible(true);
  };

  const onAdduserToGroup = async (data) => {
    setUserModalLoading(false);
    const response = await addAccess(data);
    if (response === 200) {
      notification.success({
        message: 'User added in group successfully',
        duration: 1.2
      });
      getUsersList();
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
    setUserModalVisible(false);
    setCreateUserLoader(false);
  };

  const onRemoveuserFromGroup = async (data) => {
    setUserModalLoading(false);
    const response = await removeAccess(data);
    if (response === 200) {
      notification.success({
        message: 'User remove from group successfully',
        duration: 1.2
      });
      getUsersList();
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
    setCreateUserLoader(false);
    setUserModalVisible(false);
  };

  const onUserManagment = async (event) => {
    if (event.target.value) {
      setSearchKeyExist(event.target.value);
      // const pageToken = pageTokens[pageTokens.length - 1];
      getUsersList('', '', event.target.value)
    } else {
      setSearchKeyExist('');
      getUsersList();
    }
  }
  const getNextPage = () => {
    if (pageTokens.length > 0) {
      const nextPageToken = pageTokens[pageTokens.length - 1];
      getUsersList(nextPageToken, "next");
    }
  };

  const getPrevPage = () => {
    if (pageTokens.length > 1) {
      pageTokens.pop();
      const prevPageToken = pageTokens[pageTokens.length - 1];
      pageTokens.pop();
      getUsersList(prevPageToken, "prev");
    }
  };
  return (
    <>
      <div className="h-100 overflow-y-hidden  overflow-x-auto">
        <div className="d-flex mt-0.5 justify-content-between  tab-content-title">
          <div className="d-flex align-items-center">
            <Title className="mb-0" level={4}>
              List of Users {` (${userCount})`}
            </Title>
            <div className='searchUserManagement'>
              <Input
                placeholder='Search By Email'
                onChange={onUserManagment}
                value={searchKeyExist}
                allowClear
              />
            </div>
          </div>
          <div className="text-right new-calibration">
            <Text onClick={() => showAddEditUserModal('', false)} className="ps-3 add-new-calibration"  >
              <span className="icon-22">
                <svg>
                  <use xlinkHref="#add" />
                </svg>
              </span>
              Create New User
            </Text>
          </div>
        </div>
        <hr />
        <Table
          scroll={{ x: totalUsers.length > 0 ? 1000 : 0, y: 'calc(100vh - 255px)' }}
          pagination={false}
          dataSource={userData && userData}
          className="masterUserManagmentTable"
          loading={{
            indicator: <Spin />,
            spinning: showLoader
          }}
          columns={columns}
        />
        <div className="text-right mt-3 mb-2">
          <Button
            key="prev"
            size="medium"
            shape="round"
            type="primary"
            style={{ marginRight: '10px' }}
            disabled={userCount < 60 || showLoader || totalUsers <= maxmUsersPerPage}
            onClick={getPrevPage}
          >
            Prev
            <ArrowDownOutlined />
          </Button>
          <Button
            key="next"
            size="medium"
            shape="round"
            type="primary"
            style={{ 'margin-right': '10px' }}
            disabled={userCount < 60 || showLoader || totalUsers < maxmUsersPerPage || userCount <= totalUsers}
            onClick={getNextPage}
          >
            Next
            <ArrowUpOutlined />
          </Button>
        </div>
        {userModalVisible
          ? (
            <AddUserModal
              setUserModalVisible={setUserModalVisible}
              setUserModalLoading={setUserModalLoading}
              userModalVisible={userModalVisible}
              userModalLoading={userModalLoading}
              userForm={userForm}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              onSave={saveUser}
              setCreateUserLoader={setCreateUserLoader}
              createUserLoader={createUserLoader}
              disableSendEmail={disableSendEmail}
              OnSendEmail={sendEmail}
              setDisableSendEmail={(value) => setDisableSendEmail(value)}
              userGroup={userGroup}
              setUserGroup={setUserGroup}
              setUserName={setUserName}
              userName={userName}
              OnRemoveuserFromGroup={onRemoveuserFromGroup}
              OnAdduserToGroup={onAdduserToGroup}

            />
          )
          : null}
        {
          resetPasswordModalVisible
            ? (
              <ResetPasswordModal
                setResetPasswordModalVisible={setResetPasswordModalVisible}
                resetPasswordModalVisible={resetPasswordModalVisible}
                setResetPasswordModalLoading={(value) => setResetPasswordModalLoading(value)}
                resetPasswordModalLoading={resetPasswordModalLoading}
                resetPasswordForm={resetPasswordForm}
                setResetPasswordRecord={setResetPasswordRecord}
                teamModalLoading={teamModalLoading}
                setTeamModalLoading={setTeamModalLoading}
                onResetPassword={resetPassword}
                resetPasswordRecord={resetPasswordRecord}
              />
            ) : null
        }
      </div>
    </>

  );
};

export default UserManagementPage;
