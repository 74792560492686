/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Col, Typography, Form, Row, Input, Button, message, Spin, Table, Tooltip, DatePicker, notification, Select, InputNumber, Upload } from 'antd';
import { ImportOutlined, EditOutlined, DeleteOutlined, UploadOutlined, CloudUploadOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { fetchEmployeesList, addEmployee, updateEmployee, uploadBulkEmployeeList, deleteEmployee } from '../redux/admin/employees/action'
import * as XLSX from 'xlsx';
import axios from 'axios';
import moment from 'moment';
import get from 'lodash/get';
const { Title, Text } = Typography;
import { connect } from 'react-redux';

const convertExcelDate = (serial) => {
    if (typeof serial === 'number') {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const fractional_day = serial - Math.floor(serial);
        const total_seconds = Math.floor(86400 * fractional_day);

        const seconds = total_seconds % 60;
        const hours = Math.floor(total_seconds / 3600);
        const minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(Date.UTC(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds));
    }
    return null;
};


const AdminEmployees = (props) => {
    const { onFetchEmployeesList, onAddEmployee, onUpdateEmployee, onUploadBulkEmployeeList, onDeleteEmployee, employeeList, isEmployeeListLoading } = props;

    const [fileModalVisible, setFileModalVisible] = useState(false);
    const [formModalVisible, setFormModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [ratesHeader, setRatesHeader] = useState(["CoCd", "PersNo", "Cost Ctr", "Name of Employee or Applicant", "Start Date", "End Date"]);
    const [uploadedData, setUploadedData] = useState([...employeeList]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const [isEdit, setIsEdit] = useState(false);
    const [employeeId, setEmployeeId] = useState('')
    const [userForm] = Form.useForm();
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);



    useEffect(() => {
        onFetchEmployeesList();
    }, [])
    //
    useEffect(() => {
        if (employeeList && employeeList.length) {
            setUploadedData(employeeList);
            setFilteredData(employeeList);
        }
    }, [employeeList])

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }
    //Table column
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            // align: 'center',
            width: '60px',
            render: (text, record, index) => index + 1
        },
        {
            title: 'CoCd',
            dataIndex: 'coCd',
            key: 'coCd',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'PersNo',
            dataIndex: 'personNumber',
            key: 'personNumber',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Cost Ctr',
            dataIndex: 'costCenter',
            key: 'costCenter',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            // render: (data) => {
            //     const foundType = communicationTypes.find((type) => type.code === data);
            //     return foundType ? foundType.label : data;
            // },

        },
        {
            title: 'Name of Employee',
            dataIndex: 'employeeName',
            key: 'employeeName',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (text) => moment(text).format("DD-MM-YYYY")
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (text) => moment(text).format("DD-MM-YYYY")
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
                <div>
                    <Tooltip title="Edit">
                        <EditOutlined className='txtIcon' style={{ marginLeft: '10px', fontSize: '18px' }}
                            onClick={() => showAddEditListModal(record, true)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteOutlined className='txtIcon' style={{ marginLeft: '10px', fontSize: '18px' }}
                            onClick={() => handleDeletion(record)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    //search employees records
    const onSearchEmployees = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchInput(value);

        const filtered = uploadedData.filter((employee) =>
            employee.employeeName.toLowerCase().includes(value) ||
            employee.personNumber.toLowerCase().includes(value)
        );

        setFilteredData(filtered);
    };


    //Edit employee list data
    const showAddEditListModal = async (record, edit) => {
        let userDt = {};
        if (record !== '') {
            record.startDate = moment(record.startDate);
            record.endDate = moment(record.endDate);
            userForm.setFieldsValue(record);
            setEmployeeId(record.id);
        }
        setIsEdit(edit);
        setFormModalVisible(true);
    };

    //Delete List data
    const handleDeletion = (record) => {
        Modal.confirm({
            className: 'custom-text-clr',
            title: 'Are you sure you want to delete this data?',
            async onOk() {
                try {
                    onDeleteEmployee({ id: record.id })
                } catch (error) {
                    console.error('Error deleting data:', error);
                    message.error('Data delete failed.');
                    throw error;
                }
            },
            onCancel() { },
        });
    };
    //
    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };

    //handle to add and update employees
    const handleOk = () => {
        let payload = {};
        userForm.validateFields()
            .then((data) => {
                const {
                    coCd = '',
                    personNumber = '',
                    costCenter = '',
                    employeeName = '',
                    startDate,
                    endDate
                } = data;

                if (
                    coCd.trim() &&
                    personNumber.trim() &&
                    costCenter.trim() &&
                    employeeName.trim() &&
                    startDate &&
                    endDate
                ) {
                    payload = {
                        coCd: coCd.trim(),
                        personNumber: personNumber.trim(),
                        costCenter: costCenter.trim(),
                        employeeName: employeeName.trim(),
                        startDate,
                        endDate
                    };
                    if (isEdit) {
                        onUpdateEmployee({ data: { employee: payload }, id: employeeId });
                        resetFields();
                    } else {
                        onAddEmployee(payload);
                        resetFields();
                    }
                } else {
                    notification.warning({
                        message: 'Fill all the required fields!'
                    });
                }
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo);
            });
    };


    const resetFields = () => {
        setTimeout(() => {
            setFormModalVisible(false);
            userForm.resetFields();
            setFileList([]);
            setDisableFileUpload(false);
            setIsEdit(false);
        }, 1000);
    };
    //read excel file
    const readExcelFile = async (file) => {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // excel file validation
        const requiredHeaders = [
            "CoCd", "PersNo", "Cost Ctr", "Name of Employee or Applicant", "Start Date", "End Date"
        ];
        const fileHeaders = json[0];
        const hasAllHeaders = requiredHeaders.every(header => fileHeaders.includes(header));
        if (!hasAllHeaders) {
            notification.error({
                message: 'Invalid File Structure',
                description: `The uploaded file does not have the required headers: ${requiredHeaders.join(', ')}.`,
            });
            return;
        }

        // Ensure no cells are empty
        for (let i = 1; i < json.length; i++) {
            const row = json[i];
            for (let j = 0; j < requiredHeaders.length; j++) {
                if (!row[j] && row[j] !== 0) { // Check if the cell is empty or undefined, but allow 0
                    notification.error({
                        message: 'Invalid File Data',
                        description: `The uploaded file has empty cells in row ${i + 1}, column ${fileHeaders[j]}. Please ensure all cells are filled.`,
                    });
                    return;
                }
            }
        }

        // const headers = json[0].map(cleanKey);
        const headers = ['coCd', 'personNumber', 'costCenter', 'employeeName', 'startDate', 'endDate'];
        json.shift();

        const arrayOfObjects = json.map(row => {
            let obj = {};
            row.forEach((cell, i) => {
                obj[headers[i]] = cell;
            });
            return obj;
        });

        return arrayOfObjects;
    };
    //
    const handleFileUpload = async () => {
        if (fileList.length > 0) {
            const data = await readExcelFile(fileList[0]);
            if (data?.length > 0) {
                const convertedData = data.map(record => {
                    let convertedStartDate;
                    let convertedEndDate;

                    if (typeof record.startDate === 'number') {
                        convertedStartDate = convertExcelDate(record.startDate);
                    } else if (typeof record.startDate === 'string') {
                        convertedStartDate = moment(record.startDate, "DD-MM-YYYY").toDate();
                    }

                    if (typeof record.endDate === 'number') {
                        convertedEndDate = convertExcelDate(record.endDate);
                    } else if (typeof record.endDate === 'string') {
                        convertedEndDate = moment(record.endDate, "DD-MM-YYYY").toDate();
                    }
                    return {
                        ...record,
                        startDate: convertedStartDate,
                        endDate: convertedEndDate
                    };
                });

                await onUploadBulkEmployeeList({ data: convertedData });
                setTimeout(() => {
                    setFileModalVisible(false);
                    setFileList([]);
                    setDisableFileUpload(false);
                }, 1000);
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };

    const handleDownloadExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Define the headers and their corresponding keys in uploadedData
        const headers = [
            { header: "CoCd", key: "coCd" },
            { header: "PersNo", key: "personNumber" },
            { header: "Cost Ctr", key: "costCenter" },
            { header: "Name of Employee or Applicant", key: "employeeName" },
            { header: "Start Date", key: "startDate" },
            { header: "End Date", key: "endDate" }
        ];

        // Add headers to the worksheet data
        const worksheetData = [headers.map(h => h.header)];

        // Add data rows to the worksheet data
        uploadedData.forEach(dataRow => {
            const row = headers.map(h => {
                // Convert dates to a readable format if necessary
                if (h.key === "startDate" || h.key === "endDate") {
                    return dataRow[h.key] ? moment(dataRow[h.key]).format("DD-MM-YYYY") : '';
                }
                return dataRow[h.key];
            });
            worksheetData.push(row);
        });

        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'EmployeeData');
        // Write the workbook to a binary string
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        // Convert the binary string to a blob
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        // Trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'EmployeeList.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    // Helper function to convert string to ArrayBuffer
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    };



    const handleCancel = () => {
        setIsEdit(false);
        userForm.resetFields();
        setFormModalVisible(false);
    };
    //
    const modalToImportFile = () => {
        setFileModalVisible(true);
    };
    //
    const handleCancelUploadFile = () => {
        setFileModalVisible(false);
    };
    return (
        <>
            <div className="h-100 overflow-y-hidden  overflow-x-auto">
                <div>
                    <div className='employee_admin_modal mb-2'>
                        <Title className="mb-0" level={4}>
                            List of Employees
                        </Title>
                        <div className='searchHeatProfileList'>
                            <Input
                                placeholder='Search By PersNo or Name'
                                onChange={onSearchEmployees}
                                allowClear
                            />
                        </div>
                        <div className="text-right new-calibration">
                            <Text onClick={handleDownloadExcel} className="ps-3 add-new-calibration">
                                <span className="icon-22">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024"><path fill="currentColor" d="M880 912H144c-17.7 0-32-14.3-32-32V144c0-17.7 14.3-32 32-32h360c4.4 0 8 3.6 8 8v56c0 4.4-3.6 8-8 8H184v656h656V520c0-4.4 3.6-8 8-8h56c4.4 0 8 3.6 8 8v360c0 17.7-14.3 32-32 32M770.87 199.131l-52.2-52.2c-4.7-4.7-1.9-12.8 4.7-13.6l179.4-21c5.1-.6 9.5 3.7 8.9 8.9l-21 179.4c-.8 6.6-8.9 9.4-13.6 4.7l-52.4-52.4l-256.2 256.2c-3.1 3.1-8.2 3.1-11.3 0l-42.4-42.4c-3.1-3.1-3.1-8.2 0-11.3z" /></svg>
                                </span>
                                Export to Excel
                            </Text>
                            <Text onClick={modalToImportFile} className="ps-3 add-new-calibration">
                                <span className="icon-22">
                                    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h9l5 5v11c0 1.1-.9 2-2 2zM9 13h2v4h2v-4h2l-3-3-3 3z" />
                                    </svg>
                                </span>
                                Import File
                            </Text>
                            <Text onClick={() => setFormModalVisible(true)} className="ps-3 add-new-calibration"  >
                                <span className="icon-22">
                                    <svg>
                                        <use xlinkHref="#add" />
                                    </svg>
                                </span>
                                Create New
                            </Text>

                        </div>
                    </div>
                </div>
                <hr />
                {(filteredData && filteredData.length) ?
                    <Table
                        // pagination={false}
                        columns={columns}
                        dataSource={filteredData}
                        // className="h-100 pipeline-table heatprofile_table "
                        className="masterUserManagmentTable"
                        scroll={{ x: uploadedData.length > 0 ? 1220 : 0, y: 'calc(100vh - 280px)' }}
                        pagination={{
                            position: ['bottomRight'],
                            showSizeChanger: true,  // Allows selecting different page sizes
                            total: filteredData.length,
                            current: current,      // Controlled by state
                            pageSize: pageSize,    // Controlled by state
                            onChange: (page, newPageSize) => {
                                setCurrent(page);
                                setPageSize(newPageSize);  // Update page size dynamically
                            },
                        }}
                        loading={{
                            indicator: <div><Spin /> Loading...</div>,
                            spinning: isEmployeeListLoading
                        }}
                    /> : <Table
                        pagination={false}
                        dataSource={filteredData}
                        loading={{
                            indicator: <div><Spin /> Loading...</div>,
                            spinning: isEmployeeListLoading
                        }}
                    />}



            </div>
            <Modal
                width={800}
                className="toolbox-admin"
                title={isEdit ? 'Update employee' : 'Add new employee'}
                centered
                visible={formModalVisible}
                footer={isEdit ? [
                    <Button key="submit1" type="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button key="submit2"
                        type='primary'
                        loading={isEmployeeListLoading}
                        onClick={handleOk}
                    >
                        {isEmployeeListLoading ? "Updating" : "Update"}
                    </Button>
                ] : [
                    <Button key="submit3" type="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit4"
                        size="medium"
                        shape="round"
                        type='primary'
                        loading={isEmployeeListLoading}
                        onClick={handleOk}
                    >
                        {isEmployeeListLoading ? "Creating" : "Create"}
                    </Button>
                ]}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getcontainer={false}
                    form={userForm}
                >
                    <Row gutter={12} className="upload-row">
                        <Col lg={8}>
                            <Form.Item
                                label="CoCd"
                                name="coCd"
                                required
                            >
                                <Input
                                    placeholder="Enter CoCd"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                label="PersNo"
                                name="personNumber"
                                required
                            >
                                <Input
                                    placeholder="Enter PersNo"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                label="Cost Ctr"
                                name="costCenter"
                                required
                            >
                                <Input
                                    placeholder="Enter Cost Ctr"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                label="Name of Employee"
                                name="employeeName"
                                required
                            >
                                <Input
                                    placeholder="Enter Name of Employee"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                label="Start Date"
                                name="startDate"
                                className="date-picker"
                                required
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                label="End Date"
                                name="endDate"
                                className="date-picker"
                                required
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    size="large"
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal >
            <Modal
                className="pipeline-modal"
                title='Upload Employees Data'
                centered
                visible={fileModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleFileUpload}>
                        {isEmployeeListLoading ? "Uploading" : "Upload"}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = ({ employeesReducer }) => {
    const employeeList = get(employeesReducer, 'employeesList', []);
    const isEmployeeListLoading = get(employeesReducer, 'isEmployeesListLoading', []);
    return {
        employeeList,
        isEmployeeListLoading
    }
}

const mapDispatchToProps = {
    onFetchEmployeesList: fetchEmployeesList,
    onAddEmployee: addEmployee,
    onUploadBulkEmployeeList: uploadBulkEmployeeList,
    onUpdateEmployee: updateEmployee,
    onDeleteEmployee: deleteEmployee,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmployees);