/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Typography, Form, Row, Col, Input, notification, Modal, Button
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import PipelineDetails from './PipelineDetails';
import AddNewPipeline from './AddNewPipeline';
import { fetchSowDetails, addSowDetails, updateSowDetails } from '../redux/sowDetails/actions';
import { updateProject } from "../redux/actions/projectAction";

const { Title, Text } = Typography;

const Details = (props) => {
    const {
        selectedProject,
        onFetchSowDetails,
        sowDetails,
        onAddSowDetails,
        onUpdateSowDetails,
        onUpdateProject
    } = props

    const [editDetailsForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pipelineData, setPipelineData] = useState([]);
    const [editValue, setEditValue] = useState(false);
    const [keyValue, setKeyValue] = useState(0);
    const [checked, setChecked] = useState(true);
    const [restrict, setRestrict] = useState(false);
    const [searchKeyExist, setSearchKeyExist] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [uniquePipeId, setUniquePipeId] = useState('');
    const [isEditLocationModalVisible, setIsEditLocationModalVisible] = useState(false);
    const [location, setLocation] = useState('');

    useEffect(() => {
        const projId = get(selectedProject, 'id', undefined);
        if (projId) {
            onFetchSowDetails(projId);
        }
        setLocation(selectedProject?.location);
    }, [selectedProject]);

    useEffect(() => {
        setPipelineData(sowDetails);
        setFilteredData(sowDetails);
    }, [sowDetails]);

    const onSave = async (pipelineSec, material, plannedWelds, stalkId, fieldJointType, blastStandard, pipeStringId, id) => {
        if (!editValue && id === 0) {
            const projectId = get(selectedProject, 'id', undefined);
            var obj = {
                pipelineSection: pipelineSec,
                material,
                plannedWelds,
                stalkId,
                fieldJointType,
                blastStandard,
                pipeStringId,
                projectId,
                // weldNamingConfig
            }
            await onAddSowDetails(obj);
        } else {
            var sowId = sessionStorage.getItem('sowId');
            var projectId = sessionStorage.getItem('projectId');

            var obj = {
                id: parseInt(sowId),
                projectId: parseInt(projectId),
                pipelineSection: pipelineSec,
                material: material,
                plannedWelds: plannedWelds,
                stalkId: stalkId,
                fieldJointType: fieldJointType,
                blastStandard: blastStandard,
                // weldNamingConfig: weldNamingConfig,
                pipeStringId: pipeStringId
            }
            await onUpdateSowDetails({ data: obj })
            // setPipelineData(editArray)
        }
        setTimeout(() => {
            const projId = get(selectedProject, 'id', undefined);
            if (projId) {
                onFetchSowDetails(projId);
            }
        }, 2000)
    };

    const onPipelineSearch = (event) => {
        const searchKey = event.target.value.trim().toLowerCase();
        setSearchKeyExist(searchKey);

        if (searchKey) {
            const filteredData = pipelineData.filter(item => item.pipelineSection.toLowerCase().includes(searchKey));
            setFilteredData(filteredData);
        } else {
            setFilteredData(pipelineData);
        }
    }

    const handleCancel = () => {
        setIsEditLocationModalVisible(false);
    }



    return (
        <div className="d-flex h-100">
            <div className="flex-fill custom-fill overflow-auto" style={{ maxWidth: '80%' }}>
                <div className="d-flex flex-column h-100">
                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">
                            <Title className="mb-0" level={4}>
                                Statement of Work
                            </Title>
                            <div className='searchConsumabels'>
                                <Input
                                    placeholder='Search By Pipeline Section'
                                    onChange={onPipelineSearch}
                                    value={searchKeyExist}
                                    allowClear
                                />
                            </div>
                            <AddNewPipeline className='details-modal'
                                setIsModalVisible={setIsModalVisible}
                                isModalVisible={isModalVisible}
                                editDetailsForm={editDetailsForm}
                                onSave={onSave}
                                editValue={editValue}
                                setEditValue={setEditValue}
                                keyValue={keyValue}
                                setKeyValue={setKeyValue}
                                selectedProject={selectedProject}
                                setChecked={setChecked}
                                checked={checked}
                                setRestrict={setRestrict}
                                restrict={restrict}
                                pipelineData={pipelineData}
                                uniquePipeId={uniquePipeId}
                            />
                        </div>
                        <div className="custom-line" />
                    </div>
                    <div className="flex-fill border-right overflow-auto"
                        style={{
                            // height: '40vh', 
                            height: 'calc(100vh - 180px)'
                        }}>
                        <div className="d-flex flex-column h-100">
                            <div className="flex-auto overflow-hidden">
                                <PipelineDetails
                                    pipelineData={filteredData}
                                    setIsModalVisible={setIsModalVisible}
                                    isModalVisible={isModalVisible}
                                    editDetailsForm={editDetailsForm}
                                    onSave={onSave}
                                    setEditValue={setEditValue}
                                    setKeyValue={setKeyValue}
                                    setChecked={setChecked}
                                    setRestrict={setRestrict}
                                    restrict={restrict}
                                    setUniquePipeId={setUniquePipeId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-fill custom-fill overflow-auto" style={{ maxWidth: '20%' }}>
                <div className="d-flex flex-column h-100">
                    <div className="flex-auto">
                        <div className="d-flex mb-3 ps-3 align-items-center tab-content-title">
                            <Title className="mb-0" level={4}>
                                Other Details
                            </Title>
                        </div>
                        <div className="custom-line" />
                    </div>

                    <div className="flex-fill">
                        <div className="d-flex flex-column h-100">
                            <div className="flex-auto overflow-hidden">
                                <div className='py-3 other-details px-3'>
                                    <Row justify="start" className="detail-rows">
                                        <Col span={9}><Text>Contract #</Text></Col>
                                        <Col span={15}><Text>:&nbsp;&nbsp;{selectedProject ? selectedProject.contractId : ''}</Text></Col>
                                    </Row>
                                    <Row justify="start" className="detail-rows">
                                        <Col span={9}><Text>PIH Ref #</Text></Col>
                                        <Col span={15}><Text>:&nbsp;&nbsp;{selectedProject ? selectedProject.pihRefId : ''}</Text></Col>
                                    </Row>
                                    <Row justify="start" className="detail-rows">
                                        <Col span={9}><Text>Contractor</Text></Col>
                                        <Col span={15}><Text>:&nbsp;&nbsp;{selectedProject ? selectedProject.contractor : ''}</Text></Col>
                                    </Row>
                                    <Row justify="start" className="detail-rows">
                                        <Col span={9}><Text>Location <EditOutlined style={{}} onClick={() => setIsEditLocationModalVisible(true)} /></Text></Col>
                                        <Col span={15}><Text>:&nbsp;&nbsp;{selectedProject ? location : ''}</Text></Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                width="25vw"
                title='Update Location'
                centered
                visible={isEditLocationModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="apply"
                        type="primary"
                        onClick={async () => {
                            var activeId = sessionStorage.getItem('activeId')
                            await onUpdateProject({ data: { location: location }, id: parseInt(activeId) });
                            setIsEditLocationModalVisible(false);
                        }}
                    >
                        Update
                    </Button>
                ]}
            >
                <div>
                    <Row gutter={24}>
                        <Col lg={24}>
                            <Input
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                size="large"
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ sow }) => {
    const sowDetails = get(sow, 'sowData', []);
    return {
        sowDetails
    }
}

const mapDispatchToProps = {
    onFetchSowDetails: fetchSowDetails,
    onAddSowDetails: addSowDetails,
    onUpdateSowDetails: updateSowDetails,
    onUpdateProject: updateProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
