/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Row, Table, Tooltip, Col, Typography, Modal, Form, Input, notification, Button, Select, Divider, Space, DatePicker } from 'antd';
const { Title, Text } = Typography;
import { readFile, utils } from "xlsx";
import ToolBox from '../components/DSR/ToolBox';
import { fetchDsrLogs } from '../redux/dsr/action'
import { connect } from 'react-redux';
import { get } from 'lodash';
import { FilePdfOutlined } from '@ant-design/icons'
import moment from 'moment';
import pdf from "../assets/template.pdf"
import { fetchToolBoxHome } from '../redux/toolbox/action';
import ToolBoxDetails from './DSR/ToolBoxDetails';

const { RangePicker } = DatePicker;

const ToolboxHome = (props) => {

    const {
        selectedProject,
        dsrLogs,
        onFetchDsrLogs,
        onFetchToolBox,
        toolBoxData,
        isToolboxLoading
    } = props;
    const dsrLog = toolBoxData.sort(
        (a, b) => new Date(b.meetingTime) - new Date(a.meetingTime)
    );

    const [isAddMemberVisible, setIsAddMemberVisible] = useState(false);
    const [selectedData, setSelectedDateData] = useState({});
    const [selectedRange, setSelectedRange] = useState([]);
    const [communicationRegisterId, setCommunicationRegisterId] = useState('');
    const [title, setTitle] = useState('');
    const [fileVersion, setFileVersion] = useState('');

    const currentDate = moment();
    const defaultRangeValue = [currentDate, currentDate];

    useEffect(() => {
        if (selectedProject && selectedProject.id) {
            // onFetchDsrLogs({ selectedProject });
            // Get today's date
            const today = new Date();
            const formattedStartDate = today.toISOString().split('T')[0];
            // const endDate = new Date(today);
            // endDate.setDate(today.getDate() + 7);
            // const formattedEndDate = endDate.toISOString().split('T')[0];
            let data = { selectedProject: selectedProject, startDate: formattedStartDate, endDate: formattedStartDate }
            onFetchToolBox(data)
            setSelectedRange([]);
        }
    }, [selectedProject]);

    const generatePDF = () => {
        let link = document.createElement('a')
        link.href = pdf
        link.download = "test.pdf"
        link.click()
    }

    const communicationTypes = [
        { code: 'SA', label: 'Safety Alert' },
        { code: 'TB', label: 'Toolbox Talk' },
        { code: 'EN', label: 'Engineering Notification' },
        { code: 'QA', label: 'Quality Alert' },
        { code: 'EA', label: 'Environmental Alert' },
    ];

    const columns = [
        {
            title: 'Date',
            dataIndex: 'meetingTime',
            key: 'date',
            render: (text, record) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { showModal(record) }}>
                        {text ? moment.utc(text).format('DD MMM YYYY') : '-'}
                    </div>
                );
            },
            align: 'center',
        },
        {
            title: 'Title',
            key: 'title',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data, record) => (
                truncateTableData(record.title)
            )
        },
        {
            title: 'Type',
            dataIndex: 'communicationType',
            key: 'communicationType',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => {
                const foundType = communicationTypes.find((type) => type.code === data);
                return foundType ? foundType.label : data;
            },

        },
        {
            title: 'Presented By',
            key: 'presenterName',
            render: (text, record) => {
                return (
                    <div>{record.presenterName}</div>
                );
            },
            align: 'center'
        },
        // {
        //     title: 'Created Time',
        //     dataIndex: 'logSubmitDate',
        //     key: 'createdTime',
        //     align: 'center',
        //     render: (text) => {
        //         return (
        //             <div>{text ? moment(text).format('DD MMM YYYY'): '-'}</div>
        //         );
        //     },
        // },
        // {
        //     title: 'Comments',
        //     dataIndex: 'comments',
        //     key: 'comments',
        //     align: 'center'
        // },
        {
            title: 'Report',
            dataIndex: 'reportDetails',
            key: 'reportDetails',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        {record && record.reportDetails !== null && JSON.stringify(record.reportDetails) != '{}' ?
                            <Tooltip title="View / Download Report" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <Button href={record?.reportDetails?.fileUrl} className='p-0 pdfIcon border-0' size='small' target="_blank" rel="noopener noreferrer">
                                        <FilePdfOutlined className='pdfIconRed' />
                                    </Button>
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="Report Not Generated" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FilePdfOutlined className='gradepdfIconRed' style={{ cursor: 'not-allowed' }} />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
        }
    ];

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }

    const showModal = (record) => {
        setTitle(record?.title);
        setFileVersion(record?.fileVersion);
        setIsAddMemberVisible(true);
        setCommunicationRegisterId(record?.communicationRegisterId);
    }

    const handleCancel = () => {
        setIsAddMemberVisible(false);
    }

    const handleCustomDateSelect = async (date, dateString) => {
        if (date) {
            setSelectedRange(date);
            let dateObj = {
                fromDate: moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss'),
                toDate: moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss'),
            }
            if (selectedProject && selectedProject.id) {
                onFetchDsrLogs({ selectedProject, dateObj });
                let data = { selectedProject: selectedProject, startDate: dateObj.fromDate, endDate: dateObj.toDate }
                onFetchToolBox(data)
            } else {
                notification.warning({
                    message: 'Please select Project to create Pipeline'
                });
            }
        } else {
            setSelectedRange(null)
            const today = new Date();
            const formattedStartDate = today.toISOString().split('T')[0];
            let data = { selectedProject: selectedProject, startDate: formattedStartDate, endDate: formattedStartDate }
            onFetchToolBox(data)
        }
    }



    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div className="inspection-logs p-0 d-flex mb-3 justify-content-between align-items-center tab-content-title">
                <Title className="mb-0" level={4}>
                    List of Communications
                </Title>
                <Space>
                    <DatePicker.RangePicker    
                        className="custom-date-picker"   
                        format="DD-MMM-YYYY"
                        onChange={handleCustomDateSelect}
                        value={selectedRange?.length === 0 ? defaultRangeValue : selectedRange}
                        // defaultValue={defaultRangeValue}
                        allowClear='true'
                    // defaultValue={[moment(selectedCustomDateInput), moment(selectedCustomDateInput)]}
                    // disabledDate={current => startDateInput && endDateInput
                    //     && (current < moment(startDateInput, "DD-MM-YYYY HH:mm") || current > moment(endDateInput, "DD-MM-YYYY HH:mm"))}
                    />
                </Space>
            </div>
            <div className="custom-line" style={{ marginTop: '25px' }} />
            <div className='addTeamDetails'>
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={[...dsrLog]}
                    className="h-100 pipeline-table "
                    scroll={{ y: 'calc(100vh - 290px)' }}
                    loading={isToolboxLoading}
                />
            </div>

            {/* <Modal
                width="95vw"
                title={
                    <Row>
                        <Col flex={1}>
                            <div>Tool Box</div>
                        </Col>
                        <Col flex={0}>
                            <div style={{ textAlign: 'right' }}>{moment(selectedData.logDate).format('DD MMM YYYY')}</div>
                        </Col>
                    </Row>
                }
                centered
                visible={isAddMemberVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
            >
                <div>
                    <ToolBox selectedProject={selectedProject} selectedData={selectedData} />
                </div>
            </Modal> */}
            <Modal
                width="75vw"
                title={
                    <Row>
                        <Col flex={1}>
                            <div className="custom-modal-title">
                                {title ? title : ''}{" - "}Version {fileVersion ? fileVersion : ''}
                            </div>
                        </Col>
                    </Row>
                }
                centered
                visible={isAddMemberVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
            >
                <ToolBoxDetails communicationRegisterId={communicationRegisterId} />
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ dsrReducer, toolBoxReducer }) => {
    const dsrLogs = get(dsrReducer, 'dsrLogsList', []);
    const isToolboxLoading = get(toolBoxReducer, 'isFetchtoolBoxDataLoading', []);
    const toolBoxData = get(toolBoxReducer, 'toolBoxHomeData', []);

    return {
        dsrLogs,
        isToolboxLoading,
        toolBoxData
    }
}

const mapDispatchToProps = {
    onFetchDsrLogs: fetchDsrLogs,
    onFetchToolBox: fetchToolBoxHome,

}

export default connect(mapStateToProps, mapDispatchToProps)(ToolboxHome);